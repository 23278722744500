import AccessGuard from "guards/access-guard";
import React from "react";
import UserManagementTable from "./containers/user-management-table/user-management-table";

const UserManagementModule = (props) => {
  return (
    <>
      <AccessGuard accessLevel={["SuperAdmin", "ClinicAdmin"]}>
        <UserManagementTable />
      </AccessGuard>
    </>
  );
};

export default UserManagementModule;
