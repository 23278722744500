import { deepClone } from "helper-methods";
import {
  getAvailableStatesForCurrentState,
  getSTaffUserInfo,
  getVisitReasons,
} from "http-calls";
import {
  getOrderActivations,
  updatePatientStatus,
  getAllWorkflows,
} from "http-calls/index";
import ExtendedActions from "modules/general/components/extended-actions/extended-actions";
import SearchInput from "modules/general/components/search-input/search-input";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import "../../style.scss";
import MaterialSelect from "modules/general/components/material-select/material-select";
import {
  getDeviceDisplaySettings,
  getAllDevicesOfAClinic,
} from "../../../../http-calls/index";
import moment from "moment";

let intervalRef = null;
let userComparer = [];

const DEFAULT_COLUMNS = [
  "First Name",
  "Last Name",
  "DOB",
  "Reason for Visit",
  "Call Number",
  "Mobile Number",
  "FIN",
];

const OrderActivationTable = (props) => {
  const [users, setUsers] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [isColumnListLoaded, setIsColumnListLoaded] = useState(false);
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);
  const [lastFetchedOn, setLastFetchedOn] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [availableStates, setAvailableStates] = useState([]);
  const [visitReasons, setVisitReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState("All");
  const [isLogoutNotifierVisible, setIsLogoutNotifierVisible] = useState(false);
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const _fetchOrderActivatedPatients = async () => {
    _initiateBackgroundRefresher();
    setLastFetchedOn(+new Date());
    try {
      const users = await getOrderActivations(props.clinicId);
      userComparer = _mergeUsers(users);
      setUsers(userComparer);
      console.log("users :>> ", users);
    } catch (error) {
      console.log("error :>> ", error);
      setUsers([]);
    }
  };

  const _loadDeviceColumns = async () => {
    try {
      // First load all devices for typeid 3
      const devices = await getAllDevicesOfAClinic(props.clinicId, 3);
      console.log("devices :>> ", devices);
      if (devices?.length) {
        const defaultDevice = devices[0];
        // Load first screen if available
        const response = await getDeviceDisplaySettings(defaultDevice.DeviceID);
        const screens = JSON.parse(response);
        if (screens?.length) {
          const defaultScreen = screens[0];
          console.log("defaultScreen :>> ", defaultScreen);
          const { ScreenColumns } = defaultScreen;
          const sortedColumns = ScreenColumns.sort(
            (c1, c2) => c1.ColumnOrder - c2.ColumnOrder
          ).map((c) => c.ScreenColumnDisplayName);
          console.log("sortedColumns :>> ", sortedColumns);
          setColumns(sortedColumns);
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
    setIsColumnListLoaded(true);
  };

  const _mergeUsers = (newUsers) => {
    return newUsers.map((patient) => {
      // Check if patinet already exists in state
      const previousData = userComparer.find(
        (p) => p.PatientID === patient.PatientID
      );
      if (previousData) {
        patient.FIN = previousData.FIN;
        patient.internalcomments = previousData.internalcomments;
      }
      return patient;
    });
  };

  const _updateComment = (id, comment) => {
    setHasChanges(true);
    const patientIndex = users.findIndex((p) => p.PatientID === id);
    users[patientIndex] = {
      ...users[patientIndex],
      internalcomments: comment,
    };
    userComparer = users;
    setUsers([...users]);
  };

  const _updateFin = (id, fin) => {
    setHasChanges(true);
    const patientIndex = users.findIndex((p) => p.PatientID === id);
    users[patientIndex] = {
      ...users[patientIndex],
      FIN: fin,
    };
    userComparer = users;
    setUsers([...users]);
  };

  const _updatePatientStatus = async (patient, status) => {
    // Call API to update the status
    props.showBottomLoader("Updating");
    let PatientStatusTypeID = status;
    await updatePatientStatus({
      ...patient,
      UpdatedBy: userInfo.email,
      PatientStatusTypeID,
    });
    await _fetchOrderActivatedPatients();
    props.hideLoader();
  };

  const _loadUserData = async () => {
    setUserInfo(props.userData);
  };

  const _loadData = async () => {
    await _fetchOrderActivatedPatients();
    props.hideLoader();
  };

  const _initiateBackgroundRefresher = () => {
    if (!intervalRef) {
      intervalRef = setInterval(() => {
        _fetchOrderActivatedPatients();
      }, 5000);
    }
  };

  const _logout = () => {
    setIsLogoutNotifierVisible(true);
  };

  const _checkIfUserHasAccess = async () => {
    // console.log('userInfo :>> ', userInfo);
    try {
      if (
        props.userData &&
        props.userData.email &&
        props.userData.email.length
      ) {
        const res = await getSTaffUserInfo(props.userData.email);
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error :>> ", error);
      _logout();
    }
  };

  const _filterRows = () => {
    let filteredRows = deepClone(users);
    const lowerCasedSearchValue = searchValue.toLowerCase().trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredRows = filteredRows.filter((row) => {
        if (row.CallNumber.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if (
          `${row.FirstName} ${row.LastName}`
            .toLowerCase()
            .indexOf(lowerCasedSearchValue) > -1
        ) {
          return true;
        }
      });
    }
    if (selectedReason !== "All") {
      filteredRows = filteredRows.filter(
        (row) => row.VisitType === selectedReason
      );
    }
    setFilteredRows(filteredRows);
  };

  const _loadVisitReasons = async () => {
    try {
      let visitReasons = [
        {
          label: "All",
          key: "All",
        },
      ];
      const response = await getVisitReasons(props.clinicId);
      if (response && response.length) {
        visitReasons = [
          ...visitReasons,
          ...response.map((reason) => ({
            label: reason.ReasonforVisit,
            key: reason.ReasonforVisit,
          })),
        ];
      }
      setVisitReasons(visitReasons);
    } catch (error) {}
  };

  const _loadStateMetadata = async () => {
    try {
      const workFlows = await getAllWorkflows(props.clinicId);
      if (workFlows && workFlows.length) {
        const activeFlow = workFlows[0];
        const availableStates = await getAvailableStatesForCurrentState(
          3,
          activeFlow.WorkFlowID
        );
        setAvailableStates(availableStates);
      } else {
        throw new Error();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (props.activeView === "orderActivationView") {
      props.showBottomLoader("Loading");
      _loadData();
    } else {
      setSearchValue("");
    }
  }, [props.activeView]);

  useEffect(() => {
    _loadUserData();
    _loadVisitReasons();
    // const clinicId = 1;
    // setClinicId(clinicId);
    return () => {
      clearInterval(intervalRef);
    };
  }, []);

  useEffect(() => {
    if (userInfo) {
      props.showLoader("Loading");
      _loadStateMetadata();
      _loadDeviceColumns();
    }
  }, [userInfo]);

  useEffect(() => {
    if (availableStates.length) {
      _loadData();
    }
  }, [availableStates]);

  useEffect(() => {
    _filterRows();
    if (!isNaN(users.length) && props.setPatientCount) {
      props.setPatientCount(users.length);
    }
  }, [users]);

  useEffect(() => {
    _filterRows();
  }, [searchValue, selectedReason]);

  const patientActions = availableStates.map((state) => ({
    label: state.Status,
    onClick: () => {
      setIsActionModalVisible(false);
      _updatePatientStatus(selectedPatient, state.StatusID);
    },
  }));

  const _showActionModal = (patient) => {
    setSelectedPatient(patient);
    setIsActionModalVisible(true);
  };

  const _renderCells = (columnName, registration) => {
    switch (columnName) {
      case "Call Number": {
        return <td data-column="Call Number">{registration.CallNumber}</td>;
      }
      case "First Name": {
        return (
          <td data-column="Name" className="nameCell">
            <p>{registration.FirstName}</p>
          </td>
        );
      }
      case "Last Name": {
        return (
          <td data-column="Name" className="nameCell">
            <p>{registration.LastName}</p>
          </td>
        );
      }
      case "DOB": {
        return (
          <td data-column="DOB">
            {moment(registration.DOB).format(process.env.REACT_APP_DATE_FORMAT)}
          </td>
        );
      }
      case "FIN": {
        return (
          <td data-column="FIN" className="finCell">
            <input
              placeholder="Enter FIN No"
              value={registration.FIN}
              onChange={(e) => _updateFin(e.target.value)}
            />
          </td>
        );
      }
      case "Reason For Visit": {
        return (
          <td data-column="RV">
            {registration.VisitType ? registration.VisitType : "--"}
          </td>
        );
      }
      case "Reason for Visit": {
        return (
          <td data-column="RV">
            {registration.VisitType ? registration.VisitType : "--"}
          </td>
        );
      }
      case "Mobile Number": {
        return (
          <td data-column="RV">
            {registration.MobileNumber ? registration.MobileNumber : "--"}
          </td>
        );
      }
      case "Status": {
        return <td data-column="Status">Registered</td>;
      }
      case "Wait Time Per Stage": {
        return <td data-column="WTPS">--</td>;
      }
      case "Wait Time of Visit": {
        return <td data-column="WTOV">--</td>;
      }
      case "Provider": {
        return <td data-column="P">--</td>;
      }
    }
  };

  if (props.activeView === "orderActivationView") {
    return (
      <>
        <ExtendedActions
          isVisible={isActionModalVisible}
          onCancel={(e) => setIsActionModalVisible(false)}
          actions={patientActions}
          modalHeight={200 + 80 * patientActions.length + "px"}
          selectedPatient={selectedPatient}
        />
        <div id="labAssistantView" className="userActivationWrapper">
          <div className="tableActions spaceBetween">
            <SearchInput value={searchValue} onChange={setSearchValue} />
            <div className="usersOptionsWrapper">
              <div className="clinicSelectorWrapper">
                {visitReasons && visitReasons.length ? (
                  <MaterialSelect
                    options={visitReasons}
                    value={selectedReason}
                    label={"Reason"}
                    onChange={(reason) => setSelectedReason(reason)}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {isColumnListLoaded ? (
            <>
              <table>
                <thead>
                  <tr>
                    {columns?.map((column, columnIndex) => (
                      <th key={columnIndex}>{column}</th>
                    ))}
                    <th>Comment</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRows.map((user) => (
                    <tr key={user.PatientID}>
                      {columns?.map((column, columnIndex) => (
                        <React.Fragment key={columnIndex}>
                          {_renderCells(column, user)}
                        </React.Fragment>
                      ))}
                      <td data-column="action">
                    <textarea
                      className="customInput"
                      value={user.internalcomments}
                      onChange={(e) =>
                        _updateComment(user.PatientID, e.target.value)
                      }
                    />
                  </td>
                      <td data-column="Status">
                        <div className="buttonsWrapper">
                          <button
                            className="actions"
                            onClick={(e) => _showActionModal(user)}
                          >
                            <i className="fa fa-cog" aria-hidden="true"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {filteredRows.length === 0 && (
                <div id="noCell">No Patients Available</div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderActivationTable);
