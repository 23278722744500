import { deepClone } from "helper-methods";
import ExtendedActions from "modules/general/components/extended-actions/extended-actions";
import SearchInput from "modules/general/components/search-input/search-input";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { hideLoader } from "redux/actions/loader-data";
import {
  getAvailableStatesForCurrentState,
  getMyRegistrations,
  updatePatientStatus,
  getVisitReasons,
  getAllWorkflows,
} from "../../../../http-calls/index";
import {
  hideBottomLoader,
  showBottomLoader,
  showLoader,
} from "../../../../redux/actions/loader-data";
import MyRegistrationRow from "../my-registration-row/my-registration-row";
import PatientCommunicationPrompt from "../patient-communication-prompt/patient-communication-prompt";
import MaterialSelect from "modules/general/components/material-select/material-select";
import { getAllDevicesOfAClinic, getDeviceDisplaySettings } from "http-calls";

let intervalRef1 = null;
let registrations = [];

const DEFAULT_COLUMNS = [
  "First Name",
  "Last Name",
  "DOB",
  "Reason for Visit",
  "Call Number",
  "Mobile Number",
  "FIN",
];

const MyRegistrations = (props) => {
  const [myRegistrations, setMyRegistrations] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);
  const [isColumnListLoaded, setIsColumnListLoaded] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [lastFetchedOn, setLastFetchedOn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [availableStates, setAvailableStates] = useState([]);
  const [visitReasons, setVisitReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState("All");
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const [
    isCommunicationModalVisible,
    setIsCommunicationModalVisible,
  ] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const _loadMyRegistrations = async () => {
    _initiateBackgroundRefresher();
    setLastFetchedOn(+new Date());
    try {
      const myRegistrations = await getMyRegistrations(
        props.userData.email,
        props.clinicId
      );
      registrations = _mergePatients([...myRegistrations]);
      setMyRegistrations(registrations);
      props.hideLoader();
    } catch (error) {
      console.log("error :>> ", error);
      props.hideLoader();
      setMyRegistrations([]);
    }
  };

  const _mergePatients = (newPatients) => {
    return newPatients.map((patient) => {
      // Check if patinet already exists in state
      const previousData = registrations.find(
        (p) => p.PatientID === patient.PatientID
      );
      if (previousData) {
        patient.FIN = previousData.FIN;
        patient.internalcomments = previousData.internalcomments;
      }
      return patient;
    });
  };

  const _updatePatient = (id, patient) => {
    const patientIndex = registrations.findIndex((p) => p.PatientID === id);
    registrations[patientIndex] = {
      ...registrations[patientIndex],
      ...patient,
    };
    setMyRegistrations([...registrations]);
  };

  const _updatePatientStatus = async (patient, status) => {
    let PatientStatusTypeID = status;
    props.showBottomLoader("Updating");
    await updatePatientStatus({
      ...patient,
      UpdatedBy: props.userData.email,
      PatientStatusTypeID,
    });
    await _loadMyRegistrations();
    props.hideLoader();
  };

  const _initiateBackgroundRefresher = () => {
    if (!intervalRef1) {
      intervalRef1 = setInterval(() => {
        _loadMyRegistrations();
      }, 5000);
    }
  };

  const _refresh = async () => {
    props.showBottomLoader("Refreshing");
    await _loadMyRegistrations();
    props.hideLoader();
  };

  const _loadData = async () => {
    props.showBottomLoader("Loading");
    await _loadMyRegistrations();
    props.hideLoader();
  };

  const _loadUserData = () => {
    setUserInfo(props.userData.email);
  };

  const _filterRows = () => {
    let filteredRows = deepClone(myRegistrations);
    const lowerCasedSearchValue = searchValue.toLowerCase().trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredRows = filteredRows.filter((row) => {
        if (row.CallNumber.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if (
          `${row.FirstName} ${row.LastName}`
            .toLowerCase()
            .indexOf(lowerCasedSearchValue) > -1
        ) {
          return true;
        }
      });
    }
    if (selectedReason !== "All") {
      filteredRows = filteredRows.filter(
        (row) => row.VisitType === selectedReason
      );
    }
    setFilteredRows(filteredRows);
  };

  const _loadVisitReasons = async () => {
    try {
      let visitReasons = [
        {
          label: "All",
          key: "All",
        },
      ];
      const response = await getVisitReasons(props.clinicId);
      if (response && response.length) {
        visitReasons = [
          ...visitReasons,
          ...response.map((reason) => ({
            label: reason.ReasonforVisit,
            key: reason.ReasonforVisit,
          })),
        ];
      }
      setVisitReasons(visitReasons);
    } catch (error) {}
  };

  const _loadDeviceColumns = async () => {
    try {
      // First load all devices for typeid 2
      const devices = await getAllDevicesOfAClinic(props.clinicId, 2);
      console.log("devices :>> ", devices);
      if (devices?.length) {
        const defaultDevice = devices[0];
        // Load first screen if available
        const response = await getDeviceDisplaySettings(defaultDevice.DeviceID);
        const screens = JSON.parse(response);
        if (screens?.length) {
          const defaultScreen = screens[0];
          console.log("defaultScreen :>> ", defaultScreen);
          const { ScreenColumns } = defaultScreen;
          const sortedColumns = ScreenColumns.sort(
            (c1, c2) => c1.ColumnOrder - c2.ColumnOrder
          ).map((c) => c.ScreenColumnDisplayName);
          console.log("sortedColumns :>> ", sortedColumns);
          setColumns(sortedColumns);
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
    setIsColumnListLoaded(true);
  };

  const _loadStateMetadata = async () => {
    try {
      // Load all flows
      const workFlows = await getAllWorkflows(props.clinicId);
      if (workFlows && workFlows.length) {
        const activeFlow = workFlows[0];
        const availableStates = await getAvailableStatesForCurrentState(
          2,
          activeFlow.WorkFlowID
        );
        console.log("availableStates :>> ", availableStates);
        setAvailableStates(availableStates);
      } else {
        throw new Error();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (availableStates.length) {
      _loadData();
    }
  }, [availableStates]);

  useEffect(() => {
    if (props.activeView === "allCheckins") {
      setSearchValue("");
    }
  }, [props.activeView]);

  useEffect(() => {
    _loadVisitReasons();
    _loadUserData();
    return () => {
      clearInterval(intervalRef1);
      intervalRef1 = null;
    };
  }, []);

  useEffect(() => {
    if (props.userData) {
      if (!(availableStates && availableStates.length)) {
        _loadStateMetadata();
        _loadDeviceColumns();
      }
    }
  }, [props.userData]);

  useEffect(() => {
    _filterRows();
    if (!isNaN(myRegistrations.length)) {
      props.setPatientCount(myRegistrations.length);
    }
  }, [myRegistrations]);

  useEffect(() => {
    _filterRows();
  }, [searchValue, selectedReason]);

  const _showActionModal = (patient) => {
    setSelectedPatient(patient);
    setIsActionModalVisible(true);
  };

  const _showCommunicationModal = (patient) => {
    setSelectedPatient(patient);
    setIsCommunicationModalVisible(true);
  };

  const patientActions = availableStates.map((state) => ({
    label: state.Status,
    onClick: () => {
      setIsActionModalVisible(false);
      _updatePatientStatus(selectedPatient, state.StatusID);
    },
  }));

  if (props.activeView === "myRegistraions") {
    return (
      <>
        <ExtendedActions
          isVisible={isActionModalVisible}
          onCancel={(e) => setIsActionModalVisible(false)}
          actions={patientActions}
          modalHeight={200 + 80 * patientActions.length + "px"}
          selectedPatient={selectedPatient}
        />
        <PatientCommunicationPrompt
          isVisible={isCommunicationModalVisible}
          onDismiss={(e) => setIsCommunicationModalVisible(false)}
          selectedPatient={selectedPatient}
          userInfo={props.userData}
          clinicId={props.clinicId}
        />
        <div id="labAssistantView">
          <div className="tableActions spaceBetween">
            <SearchInput value={searchValue} onChange={setSearchValue} />
            <div className="usersOptionsWrapper">
              <div className="clinicSelectorWrapper">
                {visitReasons && visitReasons.length ? (
                  <MaterialSelect
                    options={visitReasons}
                    value={selectedReason}
                    label={"Reason"}
                    onChange={(reason) => setSelectedReason(reason)}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {isColumnListLoaded ? (
            <>
              <table>
                <thead>
                  <tr>
                    {columns?.map((column, columnIndex) => (
                      <th key={columnIndex}>{column}</th>
                    ))}
                    <th>Comments</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRows.map((registration) => (
                    <MyRegistrationRow
                      columns={columns}
                      registration={registration}
                      showActionModal={(e) => _showActionModal(registration)}
                      showCommunicationModal={(e) =>
                        _showCommunicationModal(registration)
                      }
                      isSmsCommunicationEnabled={
                        props.isSmsCommunicationEnabled
                      }
                      onChange={(patient) =>
                        _updatePatient(registration.PatientID, patient)
                      }
                      onStatusUpdate={(status) =>
                        _updatePatientStatus(registration.PatientID, status)
                      }
                      key={registration.PatientID}
                    />
                  ))}
                </tbody>
              </table>
              {filteredRows.length === 0 && (
                <div id="noCell">No Registrations Available</div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyRegistrations);
