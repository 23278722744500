import React, { Component } from "react";
import { Helmet } from "react-helmet";
import QueueMapPointer from "modules/public-module/components/queue-map-pointer/queue-map-pointer";
import ProductFeaturesSwitcher from "modules/public-module/components/product-features-switcher/product-features-switcher";
import SuggestedAppsViewer from "modules/public-module/components/suggested-app-viewer/suggested-app-viewer";
import PublicHeader from "modules/public-module/components/public-header/public-header";
import "./contact-us-page.scss";

class ContactUsPage extends Component {
  state = {};
  componentDidMount() {
    window.scroll(0,0);
  }
  render() {
    return (
      <>
        <Helmet>
          <div>
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;1,300&display=swap"
              rel="stylesheet"
            />
          </div>

          <link
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css"
            rel="stylesheet"
            integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl"
            crossorigin="anonymous"
          />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/js/bootstrap.min.js" />
        </Helmet>
        <PublicHeader {...this.props} />
        <div className="landingPageWrapper">
          {" "}
          <div className="landingBody">
            <div className="contactFormOuterWrapper">

            <div className="contactFormWrapper">
              <div className="imagePart">
                <h4>Contact</h4>
                <p>Get in touch with Telehealth</p>
              </div>
              <div className="formPart">
                <div className="formInnerWrapper">
                <div className="formRow">
                  <div className="formCol">
                    <div className="inputWrapper">
                    <div className="label">First Name</div>
                    <input type="text"/>
                    </div>
                    </div>
                    <div className="formCol">
                    <div className="inputWrapper">
                    <div className="label">Last Name</div>
                    <input type="text"/>
                    </div>
                  </div>
                  </div>
                  <div className="formRow">
                  <div className="formCol">
                    <div className="inputWrapper">
                    <div className="label">Email</div>
                    <input type="text"/>
                    </div>
                  </div>
                  </div>
                  <div className="formRow">
                  <div className="formCol">
                    <div className="inputWrapper">
                    <div className="label">Message</div>
                    <textarea ></textarea>
                    </div>
                  </div>
                </div>
                <button className="submitBtn">
                  Submit
                </button>
                </div>
              </div>
            </div>
           
            </div>
             <div className="footer">
              <div className="container">
                <div className="row">
                  <div className="col-12 socialMediaIcons">
                    <div className="socialIconWrapper">
                      <i className="fa fa-google" aria-hidden="true" />
                    </div>
                    <div className="socialIconWrapper">
                      <i className="fa fa-facebook" aria-hidden="true" />
                    </div>
                    <div className="socialIconWrapper">
                      <i className="fa fa-linkedin" aria-hidden="true" />
                    </div>
                    <div className="socialIconWrapper">
                      <i className="fa fa-twitter" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="col-12 footerLinksWrapper">
                    <p className="footerLink">Services</p>
                    <p className="footerLink">FAQs</p>
                    <p className="footerLink">About Us</p>
                    <p className="footerLink">Contact Us</p>
                  </div>
                  <div className="col-12 copyrightText">
                    All Right Reserved @ 2021 Telehealth
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ContactUsPage;
