import React, { Component } from "react";
import "./auth-page.scss";
import LoginForm from "modules/public-module/components/login-form/login-form";
import ForgetPasswordForm from "modules/public-module/components/forget-password-form/forget-password-form";
import RegisterForm from "modules/public-module/components/register-form/register-form";
import { extractQueryParams } from "helper-methods";
import { generateAppSubTitle } from "helper-methods";
import { getClinicIdBasedOnDomain } from "helper-methods";

class AuthPage extends Component {

  _updateView = (activeView) => {
    this.props.history.push(`/public/auth?type=${activeView}`)
    // this.setState({ activeView });
  };

  _renderFormView = () => {
    // const { activeView } = this.state;
    const queryParams = extractQueryParams();
    let activeView = "login";
    if (queryParams.type && queryParams.type.length) {
      activeView = queryParams.type;
    }
    switch (activeView) {
      case "login": {
        return <LoginForm switchTo={this._updateView} {...this.props} />;
      }
      case "register": {
        return <RegisterForm switchTo={this._updateView} {...this.props} />;
      }
      case "forgot-password": {
        return <ForgetPasswordForm switchTo={this._updateView} {...this.props} />;
      }
    }
  };



  render() {

    let isTherapyClinic = getClinicIdBasedOnDomain() === 3;

    return (
      <>
        <div className="authPageWrapper" style={{backgroundColor: isTherapyClinic? '#2265B6': '#8068bb'}}>
          <div className="authCardWrapper">
            {this._renderFormView()}
            {
              isTherapyClinic? (
                <>
                  <div className="therapyClinic">
                  <img src={require("../../../../assets/img/thairlogo3.png")} alt="" />
                </div>
                </>
              ): (
                <div className="imagePart">
                  <h4>
                    {generateAppSubTitle()}
                  </h4>
                </div>
              )
            }
          </div>
        </div>
      </>
    );
  }
}

export default AuthPage;
