import Header from "modules/general/containers/header/header";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FaqPage from "./pages/faq-page/faq-page";
import AccessGuard from "guards/access-guard";

class FaqModule extends Component {
  state = {};

  render() {
    return (
      <>
        <AccessGuard
          accessLevel={["SuperAdmin", "ClinicAdmin"]}
        >
          <Header>
            <Switch>
              <Route path="/faqs" component={FaqPage} />
              <Route path="*" render={() => <Redirect to="/faqs" />} />
            </Switch>
          </Header>
        </AccessGuard>
      </>
    );
  }
}

export default FaqModule;
