import React, { Component } from "react";
import Modal from "react-awesome-modal";
import moment from "moment";
import ClinicDataParser from '../../../../utils/clinic-data-parser';
import { capitalizeFirstLetter } from '../../../../helper-methods/index';

class PatientLogViewerModal extends Component {
  state = {};
  render() {
    const userLabel = ClinicDataParser.getUserLabel();
    const { isVisible, patient, onDismiss } = this.props;

    return (
      <>
        <Modal
          visible={isVisible}
          width="1100"
          height="90%"
          effect="fadeInUp"
          onClickAway={onDismiss}
        >
          {patient ? (
            <div className="patientQuickSearchPromptWrapper">
              <div className="patientView">
                <div className="dismissBtn" onClick={onDismiss}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
                <div className="visitHistoryHeader">
                  <h3>{capitalizeFirstLetter(userLabel)} Visit Log</h3>
                  <h3>Name: {`${patient.Firstname} ${patient.LastName}`}</h3>
                </div>
                {patient && patient.stages ? (
                  <div className="visitHistoryLogs">
                    <div>
                      <div className="tbl-header">
                        <table cellPadding={0} cellSpacing={0} border={0}>
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Attended by</th>
                              <th>Updated at</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="tbl-content">
                        <table cellPadding={0} cellSpacing={0} border={0}>
                          <tbody>
                            {Object.keys(patient.stages).map(
                              (stageName, stageIndex) => (
                                <tr key={stageIndex}>
                                  <td>{stageName}</td>
                                  <td>
                                    {" "}
                                    by{" "}
                                    <strong>
                                      {patient.stages[stageName].updatedBy}
                                    </strong>
                                  </td>
                                  <td>
                                    {" "}
                                    at{" "}
                                    <strong>
                                      {moment(
                                        patient.stages[stageName].updatedAt
                                      ).format("MM/DD/YYYY hh:mm")}
                                    </strong>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </Modal>
      </>
    );
  }
}

export default PatientLogViewerModal;
