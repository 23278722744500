import AccessGuard from "guards/access-guard";
import Header from "modules/general/containers/header/header";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardPage from "./pages/dashboard-page/dashboard-page";
class DashboardModule extends Component {
  state = {};

  render() {
    return (
      <>
        <AccessGuard
          accessLevel={["SuperAdmin", "ClinicAdmin", "Registration"]}
        >
          <Header>
            <Switch>
              <Route path="/dashboard" component={DashboardPage} />
              <Route path="*" render={() => <Redirect to="/dashboard" />} />
            </Switch>
          </Header>
        </AccessGuard>
      </>
    );
  }
}

export default DashboardModule;
