import { store } from '../redux/store';

class ClinicDataParser {
  static getUserLabel () {
    const state = store.getState();
    if (state?.userData?.selectedClinic?.Userlabel?.length) {
      return state?.userData?.selectedClinic?.Userlabel;
    }
    return "User";
  }
}
 
export default ClinicDataParser;