import AccessGuard from 'guards/access-guard';
import React from 'react';
import ClinicManagementTable from './containers/clinic-management-table/clinic-management-table';

const ClinicManagementModule = (props) => {
  return (
    <>
      <AccessGuard accessLevel={["SuperAdmin"]}>
        <ClinicManagementTable {...props} />
      </AccessGuard>
    </>
  );
};

export default ClinicManagementModule;
