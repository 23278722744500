import NavPills from "components/NavPills/NavPills.js";
import LogoutNotifier from "modules/general/components/logout-notifier/logout-notifier";
import MyRegistrations from "modules/lab-assistant-module/components/my-registrations/my-registrations";
import NoAnswerView from "modules/lab-assistant-module/components/no-answer-view/no-answer-view";
import PatientEditPrompt from "modules/lab-assistant-module/components/patient-edit-prompt/patient-edit-prompt";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSTaffUserInfo } from "../../../../http-calls/index";
import { loadClinicApps } from "../../../../redux/actions/clinic-settings";
import Header from "../../../general/containers/header/header";
import CheckinsView from "../../components/checkins-view/checkins-view";
import "./lab-assistant-view.scss";
import CallToActionPrompt from "../../components/call-to-action-prompt/call-to-action-prompt";

const LabAssistantView = (props) => {
  const [activeView, setActiveView] = useState("myRegistraions");
  const [isSmsCommunicationEnabled, setIsSmsCommunicationEnabled] = useState(
    false
  );
  const [myRegistrationsCount, setMyRegistrationsCount] = useState(0);
  const [toBeRegisteredCount, setToBeRegisteredCount] = useState(0);
  const [noAnswerCount, setNoAnswerCount] = useState(0);
  const [clinicId, setClinicId] = useState("-1");
  const [isLogoutNotifierVisible, setIsLogoutNotifierVisible] = useState(false);
  const [staffInfo, setStaffInfo] = useState(null);

  const _updateActiveView = (viewIndex) => {
    if (viewIndex === 0) {
      setActiveView("myRegistraions");
    } else if (viewIndex === 1) {
      setActiveView("allCheckins");
    } else if (viewIndex === 2) {
      setActiveView("noAnswer");
    }
  };

  const _setClinicIdFromRedux = () => {
    setClinicId(props.userData.selectedClinic.ClinicID);
  };

  const _getActiveIndex = () => {
    if (activeView === "myRegistraions") {
      return 0;
    } else if (activeView === "allCheckins") {
      return 1;
    } else if (activeView === "noAnswer") {
      return 2;
    }
  };

  const _logout = () => {
    setIsLogoutNotifierVisible(true);
  };

  const _checkIfUserHasAccess = async () => {
    try {
      if (
        props.userData &&
        props.userData.email &&
        props.userData.email.length
      ) {
        const res = await getSTaffUserInfo(props.userData.email);
        setStaffInfo(res);
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error :>> ", error);
      _logout();
    }
  };

  const _fetchClinicSettings = async () => {
    const { clinicSettings } = props;
    let isSmsCommunicationEnabled = false;
    if (clinicSettings?.clinicApps?.length) {
      const communicationApp = clinicSettings.clinicApps.find(
        (app) => app.AppAddOn === "SMS Communication"
      );
      if (communicationApp) {
        isSmsCommunicationEnabled = true;
      }
    }
    setIsSmsCommunicationEnabled(isSmsCommunicationEnabled);
  };

  useEffect(() => {
    if (clinicId > -1) {
      _fetchClinicSettings();
    }
  }, [clinicId, props.clinicSettings]);

  const _generateTabLabel = (tabButtonLabel, patientCount) => {
    return (
      <>
        <div className="tabButtonWrapper">
          {tabButtonLabel}
          {patientCount && parseInt(patientCount) > 0 ? (
            <div className="customBadge">{patientCount}</div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    _checkIfUserHasAccess();
    _setClinicIdFromRedux();
    // const clinicId = 1;
    // setClinicId(clinicId);
  }, []);

  return (
    <>
      <LogoutNotifier isVisible={isLogoutNotifierVisible} />

      <Header>
        {clinicId > -1 ? (
          <>
            <NavPills
              active={_getActiveIndex()}
              color="info"
              onChange={_updateActiveView}
              tabs={[
                {
                  tabButton: _generateTabLabel(
                    "My Registrations",
                    myRegistrationsCount
                  ),
                  tabContent: <></>,
                },
                {
                  tabButton: _generateTabLabel(
                    "To be registered",
                    toBeRegisteredCount
                  ),
                  tabContent: <></>,
                },
                {
                  tabButton: _generateTabLabel("No Answer", noAnswerCount),
                  tabContent: <></>,
                },
              ]}
            />
            <MyRegistrations
              isSmsCommunicationEnabled={isSmsCommunicationEnabled}
              setActiveView={setActiveView}
              activeView={activeView}
              clinicId={clinicId}
              setPatientCount={setMyRegistrationsCount}
            />
            <CheckinsView
              activeView={activeView}
              clinicId={clinicId}
              setActiveView={setActiveView}
              logout={_logout}
              setPatientCount={setToBeRegisteredCount}
            />
            <NoAnswerView
              activeView={activeView}
              clinicId={clinicId}
              setActiveView={setActiveView}
              logout={_logout}
              setPatientCount={setNoAnswerCount}
            />
          </>
        ) : (
          <div className="clinicIdError">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p>
              This seems to be a configuration error. <br /> Please notify the
              front desk.
            </p>
          </div>
        )}
        <PatientEditPrompt />
      </Header>
      <CallToActionPrompt staffInfo={staffInfo} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    clinicSettings: state.clinicSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadClinicApps: () => dispatch(loadClinicApps()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabAssistantView);
