import React, { Component } from "react";

const tabs = [
  {
    title: "Management & Tracking",
    text:
      "Telehealth is accessible from any internet connected mobile device or computer.",
    image: require("../../../../assets/img/feature-1.png"),
  },
  {
    title: "Instant Feedback",
    text:
      "From workload analyzing to staffing the most appropriate resources, you’re able to make smarter business decisions with real-time metrics and immediate feedback. Telehealth offers a robust customer feedback portal designed for a high response rate so you can identify areas of improvement. Whether you provide a service, or sell a product, when your customer is a part of the conversation, you’re able to understand their reactions to the process and whether there’s a better way to serve them.",
    image: require("../../../../assets/img/feature-2.png"),
  },
  {
    title: "Web Booking",
    text:
      "Now you can provide your customers the choice to quickly book their tickets for some of your services directly from your unique web page. To use the web booking feature, your customers can simply visit your unique web booking page, choose any of your services, and book their ticket.",
    image: require("../../../../assets/img/feature-3.png"),
  },
];

class ProductFeaturesSwitcher extends Component {
  state = {
    currentTabIndex: 0,
  };

  _selectTab = (tabIndex) => {
    this.setState({ currentTabIndex: tabIndex });
  };

  _generateTabClass = (tabIndex) => {
    const { currentTabIndex } = this.state;
    if (tabIndex === currentTabIndex) {
      return "tab active";
    } else {
      return "tab";
    }
  };

  render() {
    const { currentTabIndex } = this.state;
    const currentTabContents = tabs[currentTabIndex];

    return (
      <>
        <div className="productFeaturesWrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="productFeatureHeaderText">
                  Product <span className="highlightedText">Features</span>
                </h2>
                <div className="smallHorizontalDivider"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 dataPartWrapper">
                <div className="tabSwitcher">
                  {tabs.map((tab, tabIndex) => (
                    <div
                      key={tabIndex}
                      className={this._generateTabClass(tabIndex)}
                      onClick={(e) => this._selectTab(tabIndex)}
                    >
                      {tab.title}
                    </div>
                  ))}
                </div>
                <div className="contentTitle">{currentTabContents.title}</div>
                <div className="contentText">{currentTabContents.text}</div>
              </div>
              <div className="col-md-6 featureImageWrapper">
                <img src={currentTabContents.image} alt="alt" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <button className="featureStartBtn">Sign up for Free</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProductFeaturesSwitcher;
