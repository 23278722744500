import React, { Component } from "react";
import "./style.scss";
import { extractQueryParams } from "helper-methods";

class MapboxNavigator extends Component {
  state = {
    hasError: false,
  };
  componentDidMount() {
    const { sLat, sLong, dLat, dLong } = extractQueryParams();
    if (
      sLat &&
      sLat.length &&
      sLong &&
      sLong.length &&
      dLat &&
      dLat.length &&
      dLong &&
      dLong.length
    ) {
      const { mapboxgl } = window;
      mapboxgl.accessToken =
        "pk.eyJ1IjoiZGVib2p5b3RpMjAiLCJhIjoiY2tqMnB0dzV6MjQwcDMxbnhvNHZpZ3llbSJ9.T6WrNOh4_WqolZgde1_qGw";
      var map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [-79.4512, 43.6568],
        zoom: 13,
      });
      const d = new window.MapboxDirections({
        accessToken: mapboxgl.accessToken,
        profile: "mapbox/driving-traffic",
        congestion: true,
        interactive: true,
      });
      map.addControl(d, 'bottom-left');
      d.setOrigin([parseFloat(sLat), parseFloat(sLong)]);
      d.setDestination([parseFloat(dLat), parseFloat(dLong)]);
      d.interactive(false);
    } else {
      this.setState({ hasError: true });
    }
  }
  render() {
    return (
      <>
        <div id="navigatorWrapper">
          <div className="mapHeaderWrapper">
            <div className="mapHeader">
              <i className="fa fa-car" aria-hidden="true"></i>
              &nbsp; &nbsp; &nbsp;<h5>Directions to clinic</h5>
            </div>
          </div>
          <div id="map"></div>
        </div>
      </>
    );
  }
}

export default MapboxNavigator;
