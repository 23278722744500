import React, { Component } from "react";

const ALL_SUGGESTED_APPS = [
  {
    iconClass: "fa fa-tablet",
    tabTitle: "Walk-In App",
    image: require("../../../../assets/img/s-apps-1.png"),
    contentHeader: "Eliminate lines",
    contentText: `
  Telehealth’s Walk-in app lets walk-in customers book queue tickets and supports SMS notifications in more than 69 languages. It also allows your customers to receive SMS, email notification, or a printed version of their tickets. Localize the Service names, SMS and printed tickets using the Admin portal.`,
  },
  {
    iconClass: "fa fa-desktop",
    tabTitle: "Display App",
    image: require("../../../../assets/img/s-apps-2.png"),
    contentHeader: "Announce Tickets",
    contentText: `
    Telehealth Display app displays and announces called queue tickets in your customers’ language. Your customers get real-time queuing information.`,
  },
  {
    iconClass: "fa fa-mobile",
    tabTitle: "Admin App",
    image: require("../../../../assets/img/s-apps-3.png"),
    contentHeader: "Valuable Insights",
    contentText: `
    The Admin app lets you call tickets, view real-time analytical reports for insights on organization, location, service, and agent performance. Now you can monitor hourly, daily, monthly, or even a custom timeframe’s activity.`,
  },
  {
    iconClass: "fa fa-tablet",
    tabTitle: "Desk App",
    image: require("../../../../assets/img/s-apps-4.png"),
    contentHeader: "Timely Feedback",
    contentText: `
    Attach tablet devices to agents’ desks to allow your customers to rate the service they’ve received even before they leave your location.`,
  },
  {
    iconClass: "fa fa-mobile",
    tabTitle: "Mobile App",
    image: require("../../../../assets/img/s-apps-5.png"),
    contentHeader: "Customer Booking",
    contentText: `
    Now your customers can book a spot in a queue or book appointments without stepping inside your location with the mobile app. Allowing your customers to book remotely reduces customer wait and service times while increasing customer satisfaction.`,
  },
  {
    iconClass: "fa fa-laptop",
    tabTitle: "Portal",
    image: require("../../../../assets/img/s-apps-6.png"),
    contentHeader: "Manage your Business",
    contentText: `
    Now you can configure your account, manage locations, services, schedule messaging and ad content from your desktop. With advanced reporting from real-time dashboards that include actionable data to serve your customers better and improve operational efficiency.`,
  },
];

class SuggestedAppsViewer extends Component {
  state = {
    currentTabIndex: 0,
  };

  _selectTab = (tabIndex) => {
    this.setState({ currentTabIndex: tabIndex });
  };

  _generateTabClass = (tabIndex) => {
    const { currentTabIndex } = this.state;
    if (tabIndex === currentTabIndex) {
      return "tab active";
    } else {
      return "tab";
    }
  };
  render() {
    const { currentTabIndex } = this.state;
    const currentTabContents = ALL_SUGGESTED_APPS[currentTabIndex];
    return (
      <>
        <div className="suggestedAppsWrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-2 suggestedAppsHeader">
                <h2>Suggested Apps</h2>
              </div>
              <div className="col-md-10 suggestedAppsTabsWrapper">
                {ALL_SUGGESTED_APPS.map((tab, tabIndex) => (
                  <div
                    key={tabIndex}
                    className={this._generateTabClass(tabIndex)}
                    onClick={(e) => this._selectTab(tabIndex)}
                  >
                    <div className="icon">
                      <i className={tab.iconClass} aria-hidden="true"></i>
                    </div>
                    <p className="label">{tab.tabTitle}</p>
                  </div>
                ))}
              </div>
              <div className="container tabContentWrapper">
                <div className="row">
                  <div className="col-md-7 imageWrapper">
                    <img src={currentTabContents.image} alt="alt" />
                  </div>
                  <div className="col-md-5 dataWrapper">
                    <div className="tabTitle">
                      {currentTabContents.contentHeader}
                    </div>
                    <div className="smallHorizontalDivider"></div>
                    <div className="tabText">
                      {currentTabContents.contentText}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SuggestedAppsViewer;
