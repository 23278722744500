import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { deepClone, extractNames } from "../../../../helper-methods";
import "./user-editor.scss";
import { withStyles } from "@material-ui/core/styles";
import NormalSelect from "modules/general/components/normal-select/normal-select";
import {
  checkIfEmailExistsInAzure,
  getGraphApiToken,
} from "../../../../http-calls";
import SuggestionInput from "modules/general/components/suggestion-input/suggestion-input";

const useStyles = makeStyles(basicsStyle);
const initialState = {
  formFields: {
    firstName: {
      value: "",
      isValid: true,
      isDirty: false,
      isRequired: true,
    },
    lastName: {
      value: "",
      isValid: true,
      isDirty: false,
      isRequired: true,
    },
    username: {
      value: "",
      isValid: false,
      isDirty: false,
      isRequired: true,
      errorText: "Please provide valid email",
    },
    roleType: {
      value: "",
      isValid: false,
      isDirty: false,
      isRequired: true,
    },
  },
  isFormValid: false,
  isUserActive: true,
  emailCheckerVisibility: false,
  graphToken: null,
  selectedUser: null,
  generalError: ''
};

class UserEditor extends Component {
  state = deepClone(initialState);

  componentDidMount() {
    this._fetchADUsers();
    if (this.props.editMode) {
      this._initializeFields();
    } else {
      this._resetFields();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isVisible && this.props.isVisible) {
      // Opened
      if (this.props.editMode) {
        this._initializeFields();
      } else {
        this._resetFields();
      }
    } else if (prevProps.isVisible && !this.props.isVisible) { 
      this._resetFields();
    }
  }

  _setGeneralError = error => {
    this.setState({ generalError: error });
  }

  _fetchADUsers = async () => {
    const graphToken = await this._fetchGraphToken();
    try {
    } catch (error) {}
  };

  _fetchGraphToken = async () => {
    try {
      const response = await getGraphApiToken();
      const { access_token: graphToken } = JSON.parse(response);
      return graphToken;
    } catch (error) {}
  };

  _initializeFields = () => {
    if (this.props.selectedUser && Object.keys(this.props.selectedUser)) {
      const { formFields } = this.state;
      formFields.firstName.value = this.props.selectedUser.FirstName;
      formFields.lastName.value = this.props.selectedUser.LastName;
      formFields.username.value = this.props.selectedUser.Username;
      formFields.roleType.value = this.props.selectedUser.UserAdminRole;
      const isUserActive = this.props.selectedUser.Active;
      this.setState({ formFields, isUserActive });
    }
  };

  _resetFields = () => {
    this.setState(deepClone(initialState));
  };

  _updateUserStatus = (isUserActive) => {
    this.setState({ isUserActive });
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _updateFieldValue = (fieldName, value) => {
    this._setGeneralError('');
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields }, () => {
      if (formFields[fieldName].isDirty) {
        // Validate
        this._validateForm();
      }
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "username": {
            const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (
              emailRegex.test(String(formFields.username.value).toLowerCase())
            ) {
              formFields.username.isValid = true;
              formFields.username.errorText = "";
            } else {
              formFields.username.isValid = false;
              formFields.username.errorText =
                "Please provide valid email address";
              isFormValid = false;
            }
            break;
          }

          case "roleType": {
            if (formFields.roleType.value.length >= 1) {
              formFields.roleType.isValid = true;
            } else {
              formFields.roleType.isValid = false;
              isFormValid = false;
            }
            break;
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _checkIfEmailExistsInAzure = async (email, graphToken) => {
    try {
      const response = await checkIfEmailExistsInAzure(email, graphToken);
      console.log("response :>> ", response);
      return response;
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  _getRoleId = (roleLabel) => {
    const role = this.props.roles.find((r) => r.UserAdminRole === roleLabel);
    return role.UserAdminRoleID;
  };

  _toggleEmailChecker = (emailCheckerVisibility) => {
    return new Promise((resolve, reject) => {
      this.setState({ emailCheckerVisibility }, () => {
        resolve();
      });
    });
  };

  /**
   * To check if the another user exists with the passed parameters 
   */
  _checkIfDuplicateUserExists = (username, roleType, userId = null) => {
    const { existingUsers, selectedUser } = this.props;
    if (userId) {
      console.log('username, roleType, userId :>> ', username, roleType, userId);
      console.log('existingUsers :>> ', existingUsers);
      const duplicateUsers = existingUsers.filter(user => user.UserAdminRole === roleType && user.Username === username );
      if (roleType === selectedUser.UserAdminRole) {
        return (duplicateUsers && duplicateUsers.length > 1);
      } else {
        return (duplicateUsers && duplicateUsers.length > 0);
      }
    } else {
      const duplicateUser = existingUsers.find(user => user.UserAdminRole === roleType && user.Username === username);
      return !!duplicateUser;
    }
  } 

  _save = async (e) => {
    e.preventDefault();
    await this._makeAllFieldDirty();
    await this._validateForm();
    let { selectedUser, formFields, isFormValid, graphToken } = this.state;
    if (isFormValid) {
      try {
        if (this.props.editMode) {
          const isDuplicateRole = this._checkIfDuplicateUserExists(this.props.selectedUser.Username, formFields.roleType.value, this.props.selectedUser.UserID);
          if (isDuplicateRole) {
            this._setGeneralError('This user already exists with same role');
          } else {
            const updatedUser = {
              FirstName: formFields.firstName.value,
              LastName: formFields.lastName.value,
              RoleTypeID: this._getRoleId(formFields.roleType.value),
              UID: this.props.selectedUser.UserID,
              Active: this.state.isUserActive,
            };
            this.props.onUserUpdate(updatedUser);
          }
        } else {
          formFields.username.errorText = "";
          formFields.username.isValid = true;
          this.setState({ formFields, isFormValid: true });
          // Create mode
          // Check if same combination exists (username + role)
          const isDuplicateRole = this._checkIfDuplicateUserExists(formFields.username.value, formFields.roleType.value);
          if (isDuplicateRole) {
            this._setGeneralError('This user already exists with same role');
          } else {
            const newUser = {
              FirstName: formFields.firstName.value,
              LastName: formFields.lastName.value,
              RoleTypeID: this._getRoleId(formFields.roleType.value),
              Username: formFields.username.value,
              Active: this.state.isUserActive,
              ExternalID: selectedUser.id
            };
            // console.log('newUser :>> ', newUser);
            this.props.onNewUserAdd(newUser);
          }
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    }
  };

  _updateSelectedUser = (user) => {
    this._setGeneralError('');
    const { formFields } = this.state;
    if (user) {
      formFields.firstName.value = extractNames(user.displayName)["firstName"];
      formFields.lastName.value = extractNames(user.displayName)["lastName"];
      formFields.username.value = user.userPrincipalName;
    } else {
      // No user
      // Reset related fields
      formFields.firstName.value = "";
      formFields.lastName.value = "";
      formFields.username.value = "";
    }
    this.setState({ selectedUser: user, formFields });
  };

  _discard = () => {
    this.props.onDiscard();
  };

  render() {
    const { formFields, emailCheckerVisibility, generalError } = this.state;
    return (
      <>
        <Modal
          visible={this.props.isVisible}
          // visible={true}
          width="550"
          height="560"
          effect="fadeInUp"
          onClickAway={this._discard}
        >
          <div id="modalFormWrapper">
            <div className="crossWrapper" onClick={this._discard}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            <h3>
              {this.props.editMode ? "Update user details" : "Add new user"}
            </h3>
            <div className="promptFormWrapper">
              {this.props.editMode ? (
                <></>
              ) : (
                <>
                  {this.props.isVisible && (
                    <SuggestionInput
                      graphToken={this.props.graphToken}
                      width={470}
                      inputLabel={"Search user by name"}
                      onSelect={
                        (value) => this._updateSelectedUser(value)
                        // this._updateFieldValue(
                        //   "username",
                        //   value && value.userPrincipalName
                        //     ? value.userPrincipalName
                        //     : ""
                        // )
                      }
                      options={this.props.adUsers}
                    />
                  )}
                </>
              )}
              {/*  */}

              <p className="formErrorText usernameError">
                {formFields.username.isDirty && !formFields.username.isValid
                  ? formFields.username.errorText
                  : ""}
              </p>
              <div className="promptFormRow">
                <div className="promptInputWrapper">
                  <CustomInput
                    labelText="User Email"
                    id="float"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: this.props.editMode ? true : null,
                      value: formFields.username.value,
                      onChange: (e) =>
                        this._updateFieldValue("username", e.target.value),
                      onBlur: () => this._markAsDirty("username"),
                    }}
                  />
                </div>
              </div>
              <div className="promptFormRow">
                <div className="promptInputWrapper">
                  <CustomInput
                    labelText="First Name"
                    id="float"
                    inputProps={{
                      readOnly: true,
                      value: formFields.firstName.value,
                      onChange: (e) =>
                        this._updateFieldValue("firstName", e.target.value),
                      onBlur: () => this._markAsDirty("firstName"),
                    }}
                  />
                  <p className="formErrorText">
                    {formFields.firstName.isDirty &&
                    !formFields.firstName.isValid
                      ? "Cannot be empty"
                      : ""}
                  </p>
                </div>
                <div className="promptInputWrapper">
                  <CustomInput
                    labelText="Last Name"
                    id="float"
                    // formControlProps={{
                    //   fullWidth: true,
                    // }}
                    inputProps={{
                      readOnly: true,
                      value: formFields.lastName.value,
                      onChange: (e) =>
                        this._updateFieldValue("lastName", e.target.value),
                      onBlur: () => this._markAsDirty("lastName"),
                    }}
                  />
                  <p className="formErrorText">
                    {formFields.lastName.isDirty && !formFields.lastName.isValid
                      ? "Cannot be empty"
                      : ""}
                  </p>
                </div>
              </div>
              <div id="userEditor">
                <NormalSelect
                  options={this.props.roles.map((r) => {
                    return {
                      label: r.UserAdminRole,
                      key: r.UserAdminRole,
                    };
                  })}
                  disabled={this.props.editMode}
                  label={"Select role"}
                  value={formFields.roleType.value}
                  onSelect={(e) =>
                    this._updateFieldValue("roleType", e.target.value)
                  }
                />
                <p className="formErrorText" style={{ marginTop: 30 }}>
                  {formFields.roleType.isDirty && !formFields.roleType.isValid
                    ? "Please select a role"
                    : ""}
                    {generalError}
                </p>
              </div>
              <div className="userStatusWrapper">
                <label className="toggle-control">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      this._updateUserStatus(!this.state.isUserActive)
                    }
                    checked={this.state.isUserActive}
                  />
                  <span className="control" />
                </label>
                <div>
                  {this.state.isUserActive
                    ? "User is Active"
                    : "User is Not Active"}
                </div>
              </div>

              <div className="formActionWrapper">
                {emailCheckerVisibility ? (
                  <Button color="success" className="saveButton">
                    <div className="userLoader"></div>
                  </Button>
                ) : (
                  <Button
                    color="success"
                    className="saveButton"
                    onClick={this._save}
                  >
                    {this.props.editMode ? "Save" : "Create"}
                  </Button>
                )}
                <Button
                  color="danger"
                  className="discardButton"
                  onClick={this._discard}
                >
                  Discard
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default UserEditor;
