import React, { Component } from "react";
import { Helmet } from "react-helmet";
import QueueMapPointer from "modules/public-module/components/queue-map-pointer/queue-map-pointer";
import ProductFeaturesSwitcher from "modules/public-module/components/product-features-switcher/product-features-switcher";
import SuggestedAppsViewer from "modules/public-module/components/suggested-app-viewer/suggested-app-viewer";
import PublicHeader from "modules/public-module/components/public-header/public-header";

class ServicesPage extends Component {
  state = {};
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <>
        <Helmet>
          <div>
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;1,300&display=swap"
              rel="stylesheet"
            />
          </div>

          <link
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css"
            rel="stylesheet"
            integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl"
            crossorigin="anonymous"
          />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/js/bootstrap.min.js" />
        </Helmet>
        <div className="landingPageWrapper">
          <PublicHeader {...this.props} />
          <div className="landingBody">
            {/* Top Container starts here */}
            <div className="topContainer">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 topHeaderWrapper">
                    <h3 className="topHeader">
                      Telehealth Appointments: Flexible Scheduling
                    </h3>
                    <div className="smallHorizontalDivider"></div>
                    <p className="topText">
                      Grow your business with Telehealth Appointments. Waste
                      less time on customers that don’t show up by allowing them
                      to schedule and maintain their own appointments instead.
                      Telehealth Appointments is the free customer booking app
                      that always displays your available time slots so your
                      clients can schedule more of your services anytime,
                      anywhere. With touchless booking, push notifications, and
                      powerful business analytics, Appointments is tailored to
                      the way you and your customers do business together.
                    </p>
                    <div className="actionWrapper">
                      <button className="topActionButtons">Get started!</button>
                    </div>
                  </div>
                  <div className="col-md-6 topHeaderImageWrapper">
                    <img
                      src={require("../../../../assets/img/landing-main-banner.png")}
                      alt="alt"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Top Container ends here */}
            {/* About Container starts here */}
            <div className="aboutWrapper">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="aboutHeader">
                      About{" "}
                      <span className="highlightedText">Telehealth’s</span>
                      <br /> Queuing System
                    </div>
                    <div className="smallHorizontalDivider"></div>
                    <p className="aboutText">
                      Telehealth is a queue management system that allows
                      businesses to handle customer queues smartly and speedily.
                      Telehealth is an intelligent and cloud-based system that
                      can monitor data related to queues in real time, and
                      collect customer feedback. Our cloud based software can
                      then assess this data to speed up the performance of your
                      agents and services.
                    </p>
                  </div>
                  <div className="col-md-6 aboutVideoWrapper">
                    <div className="aboutVideoBox">
                      <iframe
                        width="380"
                        height="250"
                        src="https://www.youtube.com/embed/DbnjO85lusM"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide aboutUsCarousel"
                      data-ride="carousel"
                    >
                      <ol className="carousel-indicators">
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to={0}
                          className="active"
                        />
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to={1}
                        />
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to={2}
                        />
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to={3}
                        />
                      </ol>
                      <div className="carousel-inner">
                        <div className="carousel-item active aboutCarouselItemWrapper">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-6 aboutCarouselImageWrapper">
                                <img
                                  src={require("../../../../assets/img/about-carousel-1.png")}
                                  alt=""
                                />
                              </div>
                              <div className="col-md-6">
                                <div className="aboutCarouselHeader">
                                  How it Works
                                </div>
                                <div className="smallHorizontalDivider"></div>
                                <div className="aboutCarouselSubHeader">
                                  1. Book a spot in a queue
                                </div>
                                <p className="aboutText">
                                  The Telehealth mobile app is a free mobile
                                  queue management system for your customers to
                                  download. With it, they can book their queuing
                                  spots and attain their tickets in the
                                  locations for the different services you
                                  offer. An ideal solution for your loyal and
                                  frequent visitors.
                                </p>
                                <button className="startNowBtn">
                                  Start Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item aboutCarouselItemWrapper">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-6 aboutCarouselImageWrapper">
                                <img
                                  src={require("../../../../assets/img/about-carousel-2.png")}
                                  alt=""
                                />
                              </div>
                              <div className="col-md-6">
                                <div className="aboutCarouselHeader">
                                  How it Works
                                </div>
                                <div className="smallHorizontalDivider"></div>
                                <div className="aboutCarouselSubHeader">
                                  2. Walk-in customers
                                </div>
                                <p className="aboutText">
                                  The Telehealth mobile app is a free mobile
                                  queue management system for your customers to
                                  download. With it, they can book their queuing
                                  spots and attain their tickets in the
                                  locations for the different services you
                                  offer. An ideal solution for your loyal and
                                  frequent visitors.
                                </p>
                                <button className="startNowBtn">
                                  Start Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item aboutCarouselItemWrapper">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-6 aboutCarouselImageWrapper">
                                <img
                                  src={require("../../../../assets/img/about-carousel-3.png")}
                                  alt=""
                                />
                              </div>
                              <div className="col-md-6">
                                <div className="aboutCarouselHeader">
                                  How it Works
                                </div>
                                <div className="smallHorizontalDivider"></div>
                                <div className="aboutCarouselSubHeader">
                                  3. Call customer tickets
                                </div>
                                <p className="aboutText">
                                  The Telehealth mobile app is a free mobile
                                  queue management system for your customers to
                                  download. With it, they can book their queuing
                                  spots and attain their tickets in the
                                  locations for the different services you
                                  offer. An ideal solution for your loyal and
                                  frequent visitors.
                                </p>
                                <button className="startNowBtn">
                                  Start Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="carousel-item aboutCarouselItemWrapper">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-6 aboutCarouselImageWrapper">
                                <img
                                  src={require("../../../../assets/img/about-carousel-4.png")}
                                  alt=""
                                />
                              </div>
                              <div className="col-md-6">
                                <div className="aboutCarouselHeader">
                                  How it Works
                                </div>
                                <div className="smallHorizontalDivider"></div>
                                <div className="aboutCarouselSubHeader">
                                  4. Monitor performance
                                </div>
                                <p className="aboutText">
                                  The Telehealth mobile app is a free mobile
                                  queue management system for your customers to
                                  download. With it, they can book their queuing
                                  spots and attain their tickets in the
                                  locations for the different services you
                                  offer. An ideal solution for your loyal and
                                  frequent visitors.
                                </p>
                                <button className="startNowBtn">
                                  Start Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* About Container ends here */}
            {/* Key Features starts here */}
            <div className="keyFeaturesWrapper">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="keyFeaturesTitle">
                      <span className="highlightedText">Key</span> Features
                    </h2>
                    <div className="smallHorizontalDivider"></div>
                  </div>
                  <div className="col-md-4 keyFeatureItem">
                    <div className="imageWrapper blue">
                      <img
                        src={require("../../../../assets/img/key-feature-1.png")}
                        alt=""
                      />
                    </div>
                    <h4>
                      Know <span className="highlightedText">who</span> is
                      coming
                    </h4>
                    <p>
                      Telehealth Queue Management System records your imminent
                      customer’s and the services they intend to use.
                    </p>
                  </div>
                  <div className="col-md-4 keyFeatureItem">
                    <div className="imageWrapper yellow">
                      <img
                        src={require("../../../../assets/img/key-feature-2.png")}
                        alt=""
                      />
                    </div>
                    <h4>
                      Manage <span className="highlightedText">Traffic</span>
                    </h4>
                    <p>
                      A cloud-based queue management system allows you to
                      monitor your customer visits at each location.
                    </p>
                  </div>
                  <div className="col-md-4 keyFeatureItem">
                    <div className="imageWrapper violet">
                      <img
                        src={require("../../../../assets/img/key-feature-3.png")}
                        alt=""
                      />
                    </div>
                    <h4>
                      Manage <span className="highlightedText">walk-in</span>{" "}
                      customers
                    </h4>
                    <p>
                      Install the walk-in app on a tablet inside your location.
                      This will allow your walk-in customers to book their
                      tickets and get their ticket number via SMS or email.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Key Features ends here */}
            {/* Queueing experience starts here */}
            <div className="queueungExpWrapper">
              <div className="container">
                <div className="row">
                  <div className="col-12 queueingExpHeader">
                    <h2>
                      <span className="highlightedText">Telehealth</span>{" "}
                      Queueing
                      <br />
                      Experience
                    </h2>
                    <div className="smallHorizontalDivider"></div>
                  </div>
                  <div className="col-12 mapWrapperCol">
                    <div className="mapWrapper">
                      <QueueMapPointer
                        pointerIndex={1}
                        text={
                          "A cloud-based queue management system allows you to monitor your customer visits at each location."
                        }
                      />
                      <QueueMapPointer
                        pointerIndex={2}
                        text={
                          "A cloud-based queue management system allows you to monitor your customer visits at each location."
                        }
                      />
                      <QueueMapPointer
                        pointerIndex={3}
                        text={
                          "A cloud-based queue management system allows you to monitor your customer visits at each location."
                        }
                      />
                      <QueueMapPointer
                        pointerIndex={4}
                        text={
                          "A cloud-based queue management system allows you to monitor your customer visits at each location."
                        }
                      />
                      <QueueMapPointer
                        pointerIndex={5}
                        text={
                          "A cloud-based queue management system allows you to monitor your customer visits at each location."
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Queueing experience ends here */}
            {/* Product Features starts here */}
            <ProductFeaturesSwitcher />
            {/* Product Features ends here */}
            {/* Perfect solution for starts here */}
            <div className="perfectSolutionWrapper">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 perfectSolutionHeader">
                    <h2>Perfect Solution for</h2>
                    <div className="smallHorizontalDivider"></div>
                  </div>
                  <div className="col-md-6 dataPart">
                    <div
                      id="perfectSolutionCarousel"
                      className="carousel slide aboutUsCarousel"
                      data-ride="carousel"
                    >
                      <ol className="carousel-indicators">
                        <li
                          data-target="#perfectSolutionCarousel"
                          data-slide-to={0}
                          className="active"
                        />
                        <li
                          data-target="#perfectSolutionCarousel"
                          data-slide-to={1}
                        />
                        <li
                          data-target="#perfectSolutionCarousel"
                          data-slide-to={2}
                        />
                        <li
                          data-target="#perfectSolutionCarousel"
                          data-slide-to={3}
                        />
                      </ol>
                      <div className="carousel-inner">
                        <div className="carousel-item active aboutCarouselItemWrapper">
                          <div className="slideText">
                            <h3>Government</h3>
                            <p>
                              Telecom companies have many locations and customer
                              agents. <strong>Telehealth</strong> Queue
                              Management System is an ideal tool to manage the
                              crowds within the locations.
                            </p>
                          </div>
                        </div>
                        <div className="carousel-item aboutCarouselItemWrapper">
                          <div className="slideText">
                            <h3>Government</h3>
                            <p>
                              Telecom companies have many locations and customer
                              agents. <strong>Telehealth</strong> Queue
                              Management System is an ideal tool to manage the
                              crowds within the locations.
                            </p>
                          </div>
                        </div>
                        <div className="carousel-item aboutCarouselItemWrapper">
                          <div className="slideText">
                            <h3>Government</h3>
                            <p>
                              Telecom companies have many locations and customer
                              agents. <strong>Telehealth</strong> Queue
                              Management System is an ideal tool to manage the
                              crowds within the locations.
                            </p>
                          </div>
                        </div>

                        <div className="carousel-item aboutCarouselItemWrapper">
                          <div className="slideText">
                            <h3>Government</h3>
                            <p>
                              Telecom companies have many locations and customer
                              agents. <strong>Telehealth</strong> Queue
                              Management System is an ideal tool to manage the
                              crowds within the locations.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 imagePart">
                    <img
                      src={require("../../../../assets/img/landing-main-banner.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Perfect solution for ends here */}
            {/* Suggested Apps starts here */}
            <SuggestedAppsViewer />
            {/* Suggested Apps ends here */}
            {/* Suggested Accessories starts here */}
            <div className="suggestedAccessoriesWrapper">
              <div className="container">
                <div className="row">
                  <div className="col-12 headetText">
                    Suggested Accessories
                    <div className="smallHorizontalDivider headerDivider"></div>
                  </div>
                  <div className="col-md-3">
                    <div className="imageWrapper">
                      <img
                        src={require("../../../../assets/img/acc-1.png")}
                        alt=""
                      />
                    </div>
                    <div className="dataPart">
                      <div className="title">Tablet Stands</div>
                      <div className="smallHorizontalDivider"></div>
                      <div className="text">
                        Tablet stands for location walk-in customers.
                      </div>
                      <button className="learnMoreBtn">Learn More</button>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="imageWrapper">
                      <img
                        src={require("../../../../assets/img/acc-2.png")}
                        alt=""
                      />
                    </div>
                    <div className="dataPart">
                      <div className="title">Printers</div>
                      <div className="smallHorizontalDivider"></div>
                      <div className="text">
                        Certified wireless bluetooth printer to print queuing
                        tickets.
                      </div>
                      <button className="learnMoreBtn">Learn More</button>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="imageWrapper">
                      <img
                        src={require("../../../../assets/img/acc-3.png")}
                        alt=""
                      />
                    </div>
                    <div className="dataPart">
                      <div className="title">Apple TV</div>
                      <div className="smallHorizontalDivider"></div>
                      <div className="text">
                        Apple TV to connect to any display and run Telehealth
                        Display App.
                      </div>
                      <button className="learnMoreBtn">Learn More</button>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="imageWrapper">
                      <img
                        src={require("../../../../assets/img/acc-4.png")}
                        alt=""
                      />
                    </div>
                    <div className="dataPart">
                      <div className="title">Amazon Fire TV</div>
                      <div className="smallHorizontalDivider"></div>
                      <div className="text">
                        Amazon Fire TV to connect to any display and run
                        Telehealth Display App.
                      </div>
                      <button className="learnMoreBtn">Learn More</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Suggested Accessories ends here */}
            <div className="footer">
              <div className="container">
                <div className="row">
                  <div className="col-12 socialMediaIcons">
                    <div className="socialIconWrapper">
                      <i className="fa fa-google" aria-hidden="true" />
                    </div>
                    <div className="socialIconWrapper">
                      <i className="fa fa-facebook" aria-hidden="true" />
                    </div>
                    <div className="socialIconWrapper">
                      <i className="fa fa-linkedin" aria-hidden="true" />
                    </div>
                    <div className="socialIconWrapper">
                      <i className="fa fa-twitter" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="col-12 footerLinksWrapper">
                    <p className="footerLink">Services</p>
                    <p className="footerLink">FAQs</p>
                    <p className="footerLink">About Us</p>
                    <p className="footerLink">Contact Us</p>
                  </div>
                  <div className="col-12 copyrightText">
                    All Right Reserved @ 2021 Telehealth
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ServicesPage;
