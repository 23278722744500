// @material-ui/core components
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useState, useEffect } from "react";
import MultiLang from "../../../../multi-lang/index";
import "./language-selector.scss";

const lanuageOptions = [
  { label: "English", languageKey: "english" },
  { label: "Español", languageKey: "spanish" },
  { label: "中文", languageKey: "chinese" },
  { label: "Հայերեն", languageKey: "armenian" },
  { label: "Русский", languageKey: "russian" },
  { label: "العربية", languageKey: "arabic" },
  { label: "Tiếng Việt", languageKey: "vietnamese" },
  { label: "한국어", languageKey: "korean" },
  { label: "Tagalog", languageKey: "tagalog" },
];

const LanguageSelector = (props) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const _selectLanguage = (language) => {
    setSelectedLanguage(language);
    setTimeout(() => {
      // Update language in redux
      MultiLang.setCurrentLang(language);
      // props.updateSelectedLanguage(selectedLanguage);
      props.proceedToStep("phoneInput");
    }, 200);
  };

  useEffect(() => {
    const { visitSettings } = props;
    const qrScannerEnabled = visitSettings.find(s => s.PatientVisitOption === "QRCode Scane");
    if (!window.isListeningToScannerUpdates && qrScannerEnabled) {
      window.isListeningToScannerUpdates = true;
      // Handle qr code scanner updates
      window.addEventListener('qrCodeRecieved', function (e) {
        if (e.detail.indexOf('?qrcodeid=')>-1) {
          props.history.push(`/express-checkin?qrcodeid=${e.detail.split('?qrcodeid=')[0]}&clinicId=${props.clinicId}`);
        } else {
          props.history.push(`/express-checkin?qrcodeid=${e.detail}&clinicId=${props.clinicId}`);
        }
      });  
    }
  }, []);

  return (
    <div id="languageSelectorPage">
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h1 className="header">Select your preferred language</h1>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {lanuageOptions.map((language, languageIndex) => (
          <GridItem xs={12} sm={12} md={4} lg={4} key={languageIndex}>
            <Button
              size="lg"
              className={
                selectedLanguage === language.languageKey
                  ? "languageSelector selected"
                  : "languageSelector"
              }
              onClick={(e) => _selectLanguage(language.languageKey)}
            >
              {language.label}
            </Button>
          </GridItem>
        ))}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <p className="noteText">
            Please note that not all text may be translated accurately or be
            translated at all.
            <br />
            Keck Medicine is not responsible for incorrect or inaccurate
            translations. Keck Medicine will not be held responsible for any
            damage or issues that may possibly result from using Google
            Translate.
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default LanguageSelector;
