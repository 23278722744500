import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./about-us-page.scss";
import QueueMapPointer from "modules/public-module/components/queue-map-pointer/queue-map-pointer";
import ProductFeaturesSwitcher from "modules/public-module/components/product-features-switcher/product-features-switcher";
import SuggestedAppsViewer from "modules/public-module/components/suggested-app-viewer/suggested-app-viewer";
import PublicHeader from "modules/public-module/components/public-header/public-header";

class AboutUsPage extends Component {
  state = {};
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <>
        <Helmet>
          <div>
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;1,300&display=swap"
              rel="stylesheet"
            />
          </div>

          <link
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css"
            rel="stylesheet"
            integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl"
            crossorigin="anonymous"
          />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/js/bootstrap.min.js" />
        </Helmet>
        <PublicHeader {...this.props} />
        <div className="landingPageWrapper">
          {" "}
          <div className="landingBody">
            {/* AboutUs container starts here */}
            <div className="AboutUsContainer">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 dataPart">
                    <div className="AboutUsHeader">
                    About Telehealth
                    </div>
                    <div className="smallHorizontalDivider"></div>
                    <div className="AboutUsText">
                    On average, you waste six months of your life standing in queues. Skiplino solves this problem by helping users to queue in advance or avoid queues altogether. <br /><br />

The app acts as a directory for all services that require you to take a ticket and wait in a queue to be served. It offers a smart mobile solution to improving traffic flow and saves people ‘a space’ by letting them enter the queue from remote locations.<br /><br />

Skiplino is revolutionary in its ability to access thousands of different organisations that utilise this queuing system. The application assists you, whether you’re a business or a customer, giving you an invaluable tool that will help eliminate wasted time and ultimately increase efficiency and productivity.<br /><br />

We want you to get the most out of your day, saving you time by eradicating irritating and unnecessary waiting. At the same time we also want to help businesses and service providers to improve overall customer experience.<br /><br />

The application that we’ve created to achieve our objectives is simple, yet extraordinarily powerful. As well as saving customers’ time, it gives businesses the ability to easily monitor their visitors and use the results to improve their service.
<br /><br />
We hope that Skiplino will make a serious contribution to removing one of life’s most frustrating situations – the need to waste time standing around in queues.
                    </div>
                    <button className="startNowBtn">Start Now</button>
                  </div>
                  <div className="col-md-6 imageWrapper">
                    <img
                      src={require("../../../../assets/img/about-page.png")}
                      alt="alt"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* AboutUs container ends here */}
        
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-12 socialMediaIcons">
                <div className="socialIconWrapper">
                  <i className="fa fa-google" aria-hidden="true" />
                </div>
                <div className="socialIconWrapper">
                  <i className="fa fa-facebook" aria-hidden="true" />
                </div>
                <div className="socialIconWrapper">
                  <i className="fa fa-linkedin" aria-hidden="true" />
                </div>
                <div className="socialIconWrapper">
                  <i className="fa fa-twitter" aria-hidden="true" />
                </div>
              </div>
              <div className="col-12 footerLinksWrapper">
                <p className="footerLink">Services</p>
                <p className="footerLink">FAQs</p>
                <p className="footerLink">About Us</p>
                <p className="footerLink">Contact Us</p>
              </div>
              <div className="col-12 copyrightText">
                All Right Reserved @ 2021 Telehealth
              </div>
            </div>
          </div>
        </div>
        </div>
      </>
    );
  }
}

export default AboutUsPage;
