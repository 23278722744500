const OneSignalRef = window.OneSignal || [];

class OneSignalHelper {
  static configure() {
    OneSignalRef.push(function () {
      OneSignalRef.init({
        appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true,
      });
    });
  }

  static showPrompt = () => {
    window.OneSignal.push(function () {
      window.OneSignal.showSlidedownPrompt();
    });
  };

  static getNotificationPermission = () => {
    return new Promise((resolve, reject) => {
      window.OneSignal.push([
        "isPushNotificationsEnabled",
        (isEnabled) => {
          resolve(isEnabled);
        },
      ]);
    });
  };

  static onSubscriptionEnable = (callback) => {
    window.OneSignal.push(() => {
      window.OneSignal.on("subscriptionChange", (isSubscribed) => {
        if (isSubscribed) {
          callback();
        }
      });
    });
  };

  static getDeviceId = () => {
    return new Promise((resolve, reject) => {
      window.OneSignal.getUserId(function (userId) {
        resolve(userId);
      });
    });
  };
}

export default OneSignalHelper;
