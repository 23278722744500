import Header from "modules/general/containers/header/header";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import VisitSystemSettingsPage from "./pages/visit-system-settings-page/visit-system-settings-page";
import AccessGuard from "guards/access-guard";

class VisitSystemsSettingsModule extends Component {
  state = {};

  render() {
    return (
      <>
        <AccessGuard accessLevel={["SuperAdmin", "ClinicAdmin"]}>
          <Header>
            <Switch>
              <Route
                path="/manage-visit-options"
                component={VisitSystemSettingsPage}
              />
              <Route
                path="*"
                render={() => <Redirect to="/manage-visit-options" />}
              />
            </Switch>
          </Header>
        </AccessGuard>
      </>
    );
  }
}

export default VisitSystemsSettingsModule;
