import React, { Component } from "react";
import Modal from "react-awesome-modal";
import "./quick-patient-search-prompt.scss";
import Loader from "react-loader-spinner";
import QuickSearchVisitLogs from "../quick-search-visit-logs/quick-search-visit-logs";
import { EventEmitter } from "utils/event-emitter";
import { searchVisits } from "http-calls";
import moment from "moment";
import { connect } from "react-redux";
import ClinicDataParser from '../../../../utils/clinic-data-parser';

const availbleOptions = ["today", "this week", "this month", "anytime"];

class QuickPatientSearchPrompt extends Component {
  state = {
    isVisible: false,
    isLoading: false,
    isLogsVisible: false,
    showResults: false,
    searchText: "",
    selectedOption: "anytime",
    patients: [],
    error: {
      isVisible: false,
      errorText: "",
    },
    selectedPatient: null,
  };

  componentDidMount() {
    this._bindEvents();
  }

  _selectFilter = (availbleOption) => {
    this.setState({ selectedOption: availbleOption }, () => {
      const { searchText } = this.state;
      if (searchText && searchText.trim().length) {
        this._search();
      }
    });
  };

  _bindEvents = () => {
    EventEmitter.subscribe("onQuickSearch", () => {
      this._showModal();
    });
  };

  _showModal = () => {
    this.setState({ isVisible: true });
  };

  _hideModal = () => {
    this.setState({ isVisible: false }, () => {
      this._resetSearch();
    });
  };

  _resetSearch = () => {
    this.setState({
      isVisible: false,
      isLoading: false,
      isLogsVisible: false,
      showResults: false,
      searchText: "",
      selectedOption: "anytime",
    });
  };

  _showLogs = (selectedPatient) => {
    this.setState({ isLogsVisible: true, selectedPatient });
  };

  _hideLogs = () => {
    this.setState({ isLogsVisible: false });
  };

  _getSearchRange = () => {
    const { selectedOption } = this.state;
    switch (selectedOption) {
      case "today": {
        return {
          startDate: moment().format("MM/DD/YYYY"),
          endDate: moment().format("MM/DD/YYYY"),
        };
      }
      case "this week": {
        return {
          startDate: moment().subtract(1, "weeks").format("MM/DD/YYYY"),
          endDate: moment().format("MM/DD/YYYY"),
        };
      }
      case "this month": {
        return {
          startDate: moment().subtract(1, "months").format("MM/DD/YYYY"),
          endDate: moment().format("MM/DD/YYYY"),
        };
      }
      case "anytime": {
        return {
          startDate: "01/01/2020",
          endDate: moment().format("MM/DD/YYYY"),
        };
      }
    }
  };

  _formatResults = (results) => {
    const formattedResults = results.map((patientVisit) => ({
      name: `${patientVisit.Firstname} ${patientVisit.LastName}`,
      date: patientVisit.UpdateDate,
      registeredBy: patientVisit.UpdatedBy,
      patientId: patientVisit.PatientID,
      patientVisitId: patientVisit.PatientVisitID,
      fin: patientVisit.FIN? patientVisit.FIN: '--'
    }));
    // Merge
    let mergedResults = {};
    formattedResults.forEach(result => {
      mergedResults[result.patientVisitId] = result;
    })
    return Object.values(mergedResults);
  };

  _search = () => {
    this.setState(
      {
        isLoading: true,
        showResults: false,
        error: {
          isVisible: false,
          errorText: "",
        },
      },
      async () => {
        const searchRange = this._getSearchRange();
        const { searchText } = this.state;
        const searchPayload = {
          clinicID: this.props.userData.selectedClinic.ClinicID,
          searchTerm: searchText.toLowerCase().trim(),
          startDate: searchRange.startDate,
          endDate: searchRange.endDate,
        };
        try {
          const searchResults = await searchVisits(searchPayload);
          const results = JSON.parse(searchResults);
          if (results === "No Data") {
            this.setState({
              isLoading: false,
              error: {
                isVisible: true,
                errorText: "No Records Found",
              },
              patients: [],
            });
          } else {
            console.log('results :>> ', results);
            const mergedResults = this._formatResults(results);
            this.setState({
              isLoading: false,
              patients: mergedResults,
              showResults: true,
            });
          }
        } catch (error) {
          console.log("error :>> ", error);
          this.setState({
            isLoading: false,
            showResults: false,
            error: {
              isVisible: true,
              errorText: "No Records Found",
            },
            patients: [],
          });
        }
      }
    );
  };

  _onDismiss = () => {
    this._hideModal();
  };

  render() {
    const {
      isVisible,
      isLogsVisible,
      isLoading,
      showResults,
      selectedOption,
      searchText,
      error,
      selectedPatient,
      patients,
    } = this.state;

    const userLabel = ClinicDataParser.getUserLabel();

    return (
      <>
        <Modal
          visible={isVisible}
          width="1100"
          height="90%"
          effect="fadeInUp"
          onClickAway={() => {}}
        >
          <div className="patientQuickSearchPromptWrapper">
            {isLogsVisible ? (
              <QuickSearchVisitLogs
                onBack={this._hideLogs}
                patient={selectedPatient}
                {...this.props}
              />
            ) : (
              <></>
            )}
            <div className="dismissBtn" onClick={this._onDismiss}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            <div className="searchView">
              <div className="header">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this._search();
                  }}
                >
                  <div className="searchWrapper">
                    <input
                      type="text"
                      placeholder={`Search ${userLabel}`}
                      value={searchText}
                      onChange={(e) => {
                        this.setState({ searchText: e.target.value });
                      }}
                    />
                    <div className="searchIcon" onClick={this._search}>
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </div>
                  </div>
                </form>
                <div className="filtersWrapper">
                  Visited: &nbsp;
                  <div className="filters">
                    {availbleOptions.map(
                      (availbleOption, availbleOptionIndex) => (
                        <div
                          className={
                            availbleOption === selectedOption ? "selected" : ""
                          }
                          key={availbleOptionIndex}
                          onClick={(e) => this._selectFilter(availbleOption)}
                        >
                          <a href="#">{availbleOption.toUpperCase()}</a>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="searchContent">
                {isLoading ? (
                  <div className="loaderWrapper">
                    <Loader
                      type="ThreeDots"
                      color="black"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {!isLoading && !error.isVisible && showResults ? (
                  <div className="searchResults">
                    <p className="searchResultsFoundText">Found 30 records</p>
                    <div>
                      <div className="tbl-header">
                        <table cellPadding={0} cellSpacing={0} border={0}>
                          <thead>
                            <tr>
                              <th>{userLabel.toUpperCase()} ID</th>
                              <th>Name</th>
                              <th className="dateCell">Date</th>
                              <th className="finCell">FIN</th>
                              <th className="attendedByCell">Registerd By</th>
                              <th className="actionCell">
                                Actions 
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="tbl-content">
                        <table cellPadding={0} cellSpacing={0} border={0}>
                          <tbody>
                            {patients.map((patient, patientIndex) => (
                              <tr key={patientIndex}>
                                <td>{patient.patientId}</td>
                                <td>{patient.name}</td>
                                <td className="dateCell">{moment(patient.date).format(
                                        "MM/DD/YYYY"
                                    )}</td>
                                <td>{patient.fin}</td>
                                <td className="attendedByCell">{patient.registeredBy}</td>
                                <td className="actionCell">
                                <button
                                      className="logView"
                                      onClick={(e) => this._showLogs(patient)}
                                    >
                                      <i
                                        className="fa fa-list"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp; View Logs
                                    </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {error.isVisible ? (
                  <div className="noResultsWrapper">{error.errorText}</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, null)(QuickPatientSearchPrompt);
