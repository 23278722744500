import React from "react";
import "./image-uploader.scss";

class ImageUploader extends React.Component {
  state = {
    selectedFile: null,
    previewString: "",
  };

  componentDidMount() {
    this.setState({ previewString: this.props.selectedFileLink });
  }

  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    console.log(file);
    this.setState(
      { selectedFile: file, previewString: URL.createObjectURL(file) },
      () => {
        this.props.onFileChange(file);
      }
    );
  }

  clearFile = () => {
    this.setState({ selectedFile: null, previewString: "" });
    this.upload.value = null;
    this.props.onFileClear();
  };

  render() {
    const { previewString } = this.state;

    return (
      <div className="imageUploaderWrapper">
        {previewString?.length ? (
          <div className="imageEditMode">
            <img src={previewString} alt="" />
            <div className="actions">
              <div
                className="changeBtn"
                onClick={() => {
                  this.upload.click();
                }}
              >
                Change Image
              </div>
              <div className="removeBtn" onClick={this.clearFile}>
                Remove Image
              </div>
            </div>
          </div>
        ) : (
          <div
            className="selectImageMode"
            onClick={() => {
              this.upload.click();
            }}
          >
            <i className="fas fa-plus"></i> &nbsp; Click To select an image
          </div>
        )}
        <input
          id="myInput"
          type="file"
          ref={(ref) => (this.upload = ref)}
          style={{ display: "none" }}
          onChange={this.onChangeFile.bind(this)}
        />
      </div>
    );
  }
}

export default ImageUploader;
