import { deepClone } from "helper-methods";
import { getAllAppSettings, updateClinicAppSettings } from "http-calls";
import React from "react";
import { connect } from "react-redux";
import { hideLoader } from "redux/actions/loader-data";
import {
  loadClinicApps,
  setClinicApps,
} from "../../../../redux/actions/clinic-settings";
import { showLoader } from "../../../../redux/actions/loader-data";
import AppBox from "../../components/app-box/app-box";
import "./manage-apps-page.scss";

class ManageAppsPage extends React.Component {
  state = {
    apps: [],
  };

  componentDidMount() {
    this._loadClinicApps();
  }

  _loadClinicApps = async () => {
    try {
      this.props.showLoader("Loading");
      const allApps = await getAllAppSettings();
      this.props.showLoader("Loading");
      await this.props.loadClinicApps();
      this.setState({ apps: allApps });
      this.props.hideLoader();
    } catch (error) {}
  };

  _mergeClinicAppStatus = () => {
    const { apps } = this.state;
    const formattedApps = deepClone(apps).map((app) => ({
      ...app,
      isEnabled: false,
    }));
    const { clinicSettings } = this.props;
    if (clinicSettings?.clinicApps?.length) {
      clinicSettings.clinicApps.forEach((clinicApp) => {
        const appIndex = formattedApps.findIndex(
          (app) => app.AppAddOnID === clinicApp.AppAddOnID
        );
        if (appIndex > -1) {
          formattedApps[appIndex].isEnabled = true;
        }
      });
    }
    return formattedApps;
  };

  _enableApp = (app) => {
    // First update in redux
    const { clinicSettings } = this.props;
    if (clinicSettings?.clinicApps) {
      clinicSettings.clinicApps.push(app);
      this.props.setClinicApps(clinicSettings.clinicApps);
      const {
        userData: {
          selectedClinic: { ClinicID },
        },
      } = this.props;
      updateClinicAppSettings({
        clinicID: ClinicID,
        ClinicAppAddOns: clinicSettings.clinicApps.map((a) => ({
          AppAddOnID: a.AppAddOnID,
        })),
      });
    }
  };

  _disableApp = (app) => {
    // First update in redux
    const { clinicSettings } = this.props;
    if (clinicSettings?.clinicApps) {
      const appIndex = clinicSettings.clinicApps.findIndex(
        (a) => a.AppAddOnID === app.AppAddOnID
      );
      clinicSettings.clinicApps.splice(appIndex, 1);
      const {
        userData: {
          selectedClinic: { ClinicID },
        },
      } = this.props;
      this.props.setClinicApps(clinicSettings.clinicApps);
      updateClinicAppSettings({
        clinicID: ClinicID,
        ClinicAppAddOns: clinicSettings.clinicApps.map((a) => ({
          AppAddOnID: a.AppAddOnID,
        })),
      });
    }
  };

  render() {
    const appsWithStatus = this._mergeClinicAppStatus();

    return (
      <>
        <div className="manageAppsPageWrapper">
          <div className="appsWrapper">
            {appsWithStatus.map((app, appIndex) => (
              <AppBox
                key={appIndex}
                app={app}
                enableApp={this._enableApp}
                disableApp={this._disableApp}
              />
            ))}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    clinicSettings: state.clinicSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    loadClinicApps: (clinicId) => dispatch(loadClinicApps(clinicId)),
    setClinicApps: (apps) => dispatch(setClinicApps(apps)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAppsPage);
