import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import React from "react";

const useStyles = makeStyles(basicsStyle);

/**
 *
 * Options = [{ label: "Option 1", key: "option1" }]
 *
 *
 */

const MaterialSelect = (props) => {
  const classes = useStyles();

  const {
    label = "Select",
    options = [],
    value = null,
    onChange = (e) => {},
    isNative = false
  } = props;

  return (
    <>
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          {label}
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select",
          }}
          native={isNative}
          >
        >
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem,
            }}
          >
            {label}
          </MenuItem>
          {options.map((option, optionIndex) => (
            <MenuItem
              key={optionIndex}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={option.key}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default MaterialSelect;
