import AccessTime from "@material-ui/icons/AccessTime";
import { extractQueryParams } from "helper-methods";
import {
  getCalledPatients,
  getClinicSystemSettingsOptions,
  getDeviceDisplaySettings,
  getSignedPatients,
} from "http-calls";
import AutoPaginatedTable from "modules/tv-display/components/auto-paginated-table/auto-paginated-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import "./tv-tables.scss";

const columnMappings = {
  "First Name": "FirstName",
  "Last Name": "LastName",
  DOB: "DOB",
  "Reason for Visit": "VisitType",
  Status: "Status",
  "Wait Time Per Stage": "StageTime",
  "Wait Time of Visit": "SignInTime",
  "Call Number": "CallNumber",
  "Mobile Number": "MobileNumber",
  Provider: "Provider",
};

let clockRef = null;
let intervalRef = null;
let isFirstLoad = true;
let signedPatientsTracker = {};

const TvTables = (props) => {
  const [calledPatients, setCalledPatients] = useState([]);
  const [signedPatients, setSignedPatients] = useState([]);
  const [clinicSettings, setClinicSettings] = useState(null);
  const [tableColumns, setTableColumns] = useState({
    signedIn: [],
    called: [],
    clinicId: -1,
    table1Name: "SignedIn Patients", // Default
    table2Name: "Called Patients", // Default
  });
  const [clinicId, setClinicId] = useState("-1");
  const [deviceId, setDeviceId] = useState("-1");
  const [currentTime, setCurrentTime] = useState(moment().format("MMM Do LTS"));
  const [currentTimeStamp, setCurrentTimeStamp] = useState(moment());

  const _loadWarningSettings = async () => {
    let clinicSettings = {
      // firstWarning: {
      //   backgroundColor: 'yellow',
      //   afterMinutes: 10
      // },
      // secondWarning: {
      //   backgroundColor: 'red',
      //   afterMinutes: 10
      // }
    };
    try {
      const response = await getClinicSystemSettingsOptions(
        tableColumns.clinicId
      );
      const firstWarningSettings = response.find(
        (setting) =>
          setting.ClinicSystemSettingType === "First Wait Time Warning"
      );
      if (
        firstWarningSettings &&
        firstWarningSettings.SettingValue1 &&
        firstWarningSettings.SettingValue1.length &&
        firstWarningSettings.SettingValue2 &&
        firstWarningSettings.SettingValue2.length
      ) {
        clinicSettings.firstWarning = {
          backgroundColor: firstWarningSettings.SettingValue1,
          afterMinutes: firstWarningSettings.SettingValue2,
        };
      }
      const secondWarningSettings = response.find(
        (setting) =>
          setting.ClinicSystemSettingType === "Second Wait Time Warning"
      );
      if (
        secondWarningSettings &&
        secondWarningSettings.SettingValue1 &&
        secondWarningSettings.SettingValue1.length &&
        secondWarningSettings.SettingValue2 &&
        secondWarningSettings.SettingValue2.length
      ) {
        clinicSettings.secondWarning = {
          backgroundColor: secondWarningSettings.SettingValue1,
          afterMinutes: secondWarningSettings.SettingValue2,
        };
      }
      const tvPaginationSettings = response.find(
        (setting) => setting.ClinicSystemSettingType === "TV Pagination"
      );
      if (
        tvPaginationSettings &&
        tvPaginationSettings.SettingValue1 &&
        tvPaginationSettings.SettingValue1.length &&
        tvPaginationSettings.SettingValue2 &&
        tvPaginationSettings.SettingValue2.length
      ) {
        clinicSettings.tvPagination = {
          rotationTime: tvPaginationSettings.SettingValue1,
          numberOfRows: tvPaginationSettings.SettingValue2,
        };
      }
    } catch (error) {}
    setClinicSettings(clinicSettings);
  };

  const _loadPatients = async () => {
    _initiateBackgroundRefresher();
    try {
      const signedPatients = await getSignedPatients(tableColumns.clinicId);
      setSignedPatients(signedPatients);
      if (isFirstLoad) {
        isFirstLoad = false;
      }
    } catch (error) {
      props.hideLoader();
      setSignedPatients([]);
      if (isFirstLoad) {
        isFirstLoad = false;
      }
    }
    try {
      const calledPatients = await getCalledPatients(tableColumns.clinicId);
      setCalledPatients(calledPatients);
    } catch (error) {
      props.hideLoader();
      setCalledPatients([]);
    }
    props.hideLoader();
  };

  const _fetchTableColumns = async () => {
    // First fetch table columns
    const tableColumnsResponse = JSON.parse(
      await getDeviceDisplaySettings(deviceId)
    );
    let tableColumns = {
      clinicId: tableColumnsResponse[0].ClinicID,
      signedIn: tableColumnsResponse[0].ScreenColumns.map(
        (c) => c.ScreenColumnDisplayName
      ),
      called: tableColumnsResponse[1].ScreenColumns.map(
        (c) => c.ScreenColumnDisplayName
      ),
      table1Name: tableColumnsResponse[0].DeviceScreen, // Default
      table2Name: tableColumnsResponse[1].DeviceScreen, // Default
    };
    setTableColumns(tableColumns);
  };

  const _initiateBackgroundRefresher = () => {
    if (!intervalRef) {
      intervalRef = setInterval(() => {
        _loadPatients();
      }, 5000);
    }
  };

  const _extractDeviceId = () => {
    const params = extractQueryParams();
    const deviceId = Object.keys(params).find(
      (param) => param.toLocaleLowerCase() === "deviceid"
    );
    if (deviceId) {
      setDeviceId(params[deviceId]);
    }
  };

  const _getTimeDifference = (signInTime) => {
    const diff = moment.duration(currentTimeStamp.diff(moment(signInTime)));
    return diff.hours() + " hrs " + diff.minutes() + " mins ";
  };

  const _formatName = (name) => {
    // First get length
    const stringLength = name.length;
    if (stringLength <= 3) {
      return name;
    } else {
      let formattedName = name.substring(0, 3);
      formattedName += name.substring(3, stringLength).replace(/./g, "*");
      return _capitalize(formattedName);
    }
  };

  const _capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };

  const _getRowStyle = (patient) => {
    const diffInMinutes = currentTimeStamp.diff(
      moment(patient.SignInTime),
      "minutes"
    );
    const styleObj = { color: "white" };
    if (clinicSettings && clinicSettings.firstWarning) {
      if (diffInMinutes > clinicSettings.firstWarning.afterMinutes) {
        styleObj.backgroundColor = clinicSettings.firstWarning.backgroundColor;
      }
    }
    if (clinicSettings && clinicSettings.secondWarning) {
      if (diffInMinutes > clinicSettings.secondWarning.afterMinutes) {
        styleObj.backgroundColor = clinicSettings.secondWarning.backgroundColor;
      }
    }
    return styleObj;
  };

  const _renderTableRows = (tableType, patient) => {
    return (
      <>
        {tableColumns[tableType].map((columnName) => (
          <td key={columnName}>{_getCell(columnName, patient)}</td>
        ))}
      </>
    );
  };

  const _notifyOnNewPatientSignIn = (notify = true) => {
    if (!signedPatients.length) {
      signedPatientsTracker = {};
    } else {
      let updatesAvailable = false;
      // Check if new patients are added with signin status
      signedPatients.forEach((patient) => {
        if (patient.Status === "SignedIn") {
          if (!signedPatientsTracker[patient.PatientID]) {
            updatesAvailable = true;
            signedPatientsTracker[patient.PatientID] = true;
          }
        }
      });
      if (notify && updatesAvailable) {
        _playSound();
      }
    }
  };

  const _playSound = () => {
    const audio = new Audio(
      require("../../../../assets/sounds/kiosk-notification.wav")
    );
    audio.play();
  };

  useEffect(() => {
    _extractDeviceId();
    clockRef = setInterval(() => {
      setCurrentTime(moment().format("MMM Do  -  LTS"));
      setCurrentTimeStamp(moment());
    }, 1000);
    return () => {
      clearInterval(clockRef);
    };
  }, []);

  useEffect(() => {
    if (deviceId !== "-1") {
      props.showLoader("Loading");
      _fetchTableColumns();
    }
  }, [deviceId]);

  useEffect(() => {
    if (
      tableColumns &&
      tableColumns.signedIn &&
      tableColumns.signedIn.length &&
      tableColumns.called &&
      tableColumns.called.length
    ) {
      props.showLoader("Loading");
      _loadPatients();
    }
  }, [clinicSettings]);

  useEffect(() => {
    if (tableColumns.clinicId > -1) {
      _loadWarningSettings();
    }
  }, [tableColumns]);

  useEffect(() => {
    if (!isFirstLoad) {
      _notifyOnNewPatientSignIn(true);
    } else {
      _notifyOnNewPatientSignIn(false);
    }
  }, [signedPatients]);

  const _renderTableColumns = (tableType) => {
    return (
      <>
        {tableColumns[tableType].map((columnName) => (
          <th key={columnName}>{columnName}</th>
        ))}
      </>
    );
  };

  const _getCell = (columnName, patient) => {
    switch (columnMappings[columnName]) {
      case "FirstName": {
        return _formatName(patient.FirstName);
      }
      case "LastName": {
        return _formatName(patient.LastName);
      }
      case "VisitType": {
        return patient.VisitType.toUpperCase();
      }
      case "SignInTime": {
        return _getTimeDifference(patient.SignInTime);
      }
      case "StageTime": {
        return _getTimeDifference(patient.StageTime);
      }
      case "DOB": {
        return moment(patient.DOB).format("DD-MM-YYYY");
      }
      default: {
        return patient[columnMappings[columnName]]
          ? patient[columnMappings[columnName]]
          : "--";
      }
    }
  };

  const formattedSignedInTableRows = signedPatients.map((signedPatient) => {
    const formattedRow = {
      cells: [],
      rowStyleObject: _getRowStyle(signedPatient),
    };
    formattedRow.cells = tableColumns["signedIn"].map((columnName) =>
      _getCell(columnName, signedPatient)
    );
    return formattedRow;
  });
  const formattedCalledInTableRows = calledPatients.map((calledPatient) => {
    const formattedRow = {
      cells: [],
      rowStyleObject: _getRowStyle(calledPatient),
    };
    formattedRow.cells = tableColumns["called"].map((columnName) =>
      _getCell(columnName, calledPatient)
    );
    return formattedRow;
  });

  return (
    <>
      <div id="tvTablesWrapper">
        <div className="tvHeader">
          <div>Checkins</div>
          <div className="clockWrapper">
            <AccessTime /> {currentTime}
          </div>
        </div>
        {clinicSettings && tableColumns.clinicId > -1 ? (
          <div className="tableWrapper">
            {clinicSettings.tvPagination ? (
              <>
                <AutoPaginatedTable
                  tableHeaders={tableColumns.signedIn}
                  tableRows={formattedSignedInTableRows}
                  noPatientMessage="No Signed In Patients"
                  tableTitle="Signed Patients"
                  rowsPerView={
                    (clinicSettings &&
                      clinicSettings.tvPagination &&
                      clinicSettings.tvPagination.numberOfRows) ||
                    3
                  }
                  refreshIntervalInSeconds={
                    (clinicSettings.tvPagination &&
                      clinicSettings.tvPagination.rotationTime) ||
                    3
                  }
                />
                <AutoPaginatedTable
                  tableHeaders={tableColumns.called}
                  tableRows={formattedCalledInTableRows}
                  noPatientMessage="No Called In Patients"
                  tableTitle="Called Patients"
                  rowsPerView={
                    (clinicSettings &&
                      clinicSettings.tvPagination &&
                      clinicSettings.tvPagination.numberOfRows) ||
                    3
                  }
                  refreshIntervalInSeconds={
                    (clinicSettings.tvPagination &&
                      clinicSettings.tvPagination.rotationTime) ||
                    3
                  }
                />
              </>
            ) : (
              <>
                <div className="table">
                  <div className="tableHeader">{tableColumns.table1Name}</div>
                  <table>
                    <thead>
                      <tr>{_renderTableColumns("signedIn")}</tr>
                    </thead>
                    <tbody>
                      {signedPatients.map((patient, patientIndex) => (
                        <tr key={patientIndex} style={_getRowStyle(patient)}>
                          {_renderTableRows("signedIn", patient)}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {!signedPatients.length ? (
                    <div id="noCell">No Patients Available</div>
                  ) : null}
                </div>
                <div className="table">
                  <div className="tableHeader">{tableColumns.table2Name}</div>
                  <table>
                    <thead>
                      <tr>{_renderTableColumns("called")}</tr>
                    </thead>
                    <tbody>
                      {calledPatients.map((patient, patientIndex) => (
                        <tr key={patientIndex}>
                          {_renderTableRows("called", patient)}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {!calledPatients.length ? (
                    <div id="noCell">No Patients Available</div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="clinicIdError" style={{ height: "100vh" }}>
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p>
              This seems to be a configuration error. <br /> Please notify the
              front desk.
            </p>
          </div>
        )}{" "}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

export default connect(null, mapDispatchToProps)(TvTables);
