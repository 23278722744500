import AccessGuard from "guards/access-guard";
import Header from "modules/general/containers/header/header";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import FlowEditor from "./pages/flow-editor/flow-editor";
import ManageFlows from "./pages/manage-flows/manage-flows";

class FlowCustomizer extends Component {
  state = {};

  _shouldShow = () => {
    if (this.props?.clinicSettings?.clinicApps) {
      const screenBuilderIndex = this.props.clinicSettings.clinicApps.findIndex(
        (a) => a.AppAddOnID === 5
      );
      if (screenBuilderIndex === -1) {
        return false;
      }
    }
    return true;
  };

  render() {
    let isVisible = this._shouldShow();
    return (
      <>
        <AccessGuard accessLevel={["SuperAdmin", "ClinicAdmin"]}>
          <Header>
            {isVisible ? (
              <Switch>
                <Route path="/manage-flows/builder" component={FlowEditor} />
                <Route path="/manage-flows" component={ManageFlows} />
                <Route
                  path="*"
                  render={() => <Redirect to="/manage-flows" />}
                />
              </Switch>
            ) : (
              <></>
            )}
          </Header>
        </AccessGuard>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    clinicSettings: state.clinicSettings,
  };
};

export default connect(mapStateToProps, null)(FlowCustomizer);
