import Sync from "@material-ui/icons/Sync";
import { extractQueryParams } from "helper-methods";
import { getClinicAppSettings } from "http-calls";
import { fetchDataFromQrId, getPatientWaitTime } from "http-calls";
import moment from "moment";
import { default as React, useEffect, useState } from "react";
import Progress from "react-progressbar";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "redux/actions/loader-data";
import LanguageParsedText from "../../multi-lang/language-parser";
import Header from "./containers/header/header";
// import "./registration-success.scss";
import "./style.scss";

let timerInterval = null;
let seconds = 0;

const ExpressCheckinModule = (props) => {
  const [waitSeconds, setWaitSeconds] = useState(0);
  const [waitTime, setWaitTime] = useState(null);
  const [error, seteError] = useState(null);
  const [patient, setPatient] = useState(null);

  const _checkIfExpressCheckinEnabled = async (clinicId) => {
    try {
      const { clinicId } = this.state;
      const clinicApps = await getClinicAppSettings(clinicId);
      let isExpressCheckinEnabled = false;
      const qrCheckinApp = clinicApps.find(
        (app) => app.AppAddOn === "QR Express Checkin"
      );
      if (qrCheckinApp) {
        isExpressCheckinEnabled = true;
      }
      return isExpressCheckinEnabled;
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const _fetchWaitTime = async (patient) => {
    const date = moment().format("MM-DD-YYYY");
    try {
      const dateResponse = await getPatientWaitTime(patient.ClinicID, date);
      if (dateResponse && dateResponse.AvgTime && dateResponse.AvgTime.length) {
        setWaitTime(dateResponse.AvgTime);
      }
    } catch (error) {}
  };

  const _extractQrCodeId = () => {
    const params = extractQueryParams();
    if (params && params.qrcodeid && params.qrcodeid.length) {
      return params.qrcodeid;
    } else {
      return null;
    }
  };

  const _initiateRedirectTimer = () => {
    timerInterval = setInterval(() => {
      seconds++;
      setWaitSeconds(seconds);
      if (seconds === process.env.REACT_APP_SUCCESS_VIEW_WAIT_TIME) {
        clearInterval(timerInterval);
        seconds = 0;
        setWaitSeconds(seconds);
        _restart();
      }
    }, 1000);
  };

  const _initiateLoad = async () => {
    props.showLoader("Please wait");
    try {
      // First try to extract qrcode id from params
      const qrCodeId = _extractQrCodeId();
      if (qrCodeId) {
        // QR code available
        // Try to fetch patient data
        const patientResponse = await fetchDataFromQrId(qrCodeId);
        if (patientResponse && patientResponse.length) {
          const patient = patientResponse[0];
          const isExpressCheckinEnabled = await _checkIfExpressCheckinEnabled(
            patient.ClinicID
          );
          if (isExpressCheckinEnabled) {
            // Patient data found
            setPatient(patient);
            // Now try to get estimated wait time
            // await _fetchWaitTime(patient);
            // Trigger timer
            _initiateRedirectTimer();
            props.hideLoader();
          } else {
            throw "Access Denied";
          }
        } else {
          throw "No patient found";
        }
      } else {
        throw "No Qr Code";
      }
    } catch (error) {
      seteError(error);
      props.hideLoader();
    }
  };

  const _getCallNumber = () => {
    if (patient && patient.CallNumber && patient.CallNumber.length) {
      return patient.CallNumber;
    } else if (patient && patient.MobileNumber && patient.MobileNumber.length) {
      return patient.MobileNumber.slice(-4);
    }
  };

  const _restart = () => {
    props.history.push("/intakeform?clinicId=1");
  };

  useEffect(() => {
    _initiateLoad();
  }, []);

  const _getPercentage = () => {
    return (
      100 * (1 - waitSeconds / process.env.REACT_APP_SUCCESS_VIEW_WAIT_TIME)
    );
  };

  return (
    <>
      <Header {...props} />
      {error ? (
        <div className="clinicIdError" style={{ height: "100vh" }}>
          <button id="startOver" onClick={_restart} style={{ zIndex: "55" }}>
            <Sync />{" "}
            <span id="newRegistartion">
              <LanguageParsedText value="startover" />
            </span>
          </button>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p>{error}</p>
        </div>
      ) : (
        <div id="wizardWrapper">
          <button id="startOver" onClick={_restart} style={{ zIndex: "55" }}>
            <Sync />{" "}
            <span id="newRegistartion">
              <LanguageParsedText value="startover" />
            </span>
          </button>
          <div id="registrationSuccess">
            {patient && (
              <>
                <h3>
                  <LanguageParsedText value="callnumberdisplaylabel" />{" "}
                  <span>{_getCallNumber()}</span>.<br />
                  <LanguageParsedText value="waittext" />
                  {waitTime && (
                    <div style={{ fontSize: "2rem", fontWeight: "600" }}>
                      Estimated wait time: {waitTime}
                    </div>
                  )}
                </h3>
                <div className="progressWrapper">
                  <Progress completed={_getPercentage()} />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpressCheckinModule);
