import React, { Component } from "react";
import { showLoader } from "redux/actions/loader-data";
import { hideLoader } from "redux/actions/loader-data";
import { updateUserData } from "redux/actions/user-data";
import { connect } from "react-redux";
import FirebaseAuthService from "firebase-auth-service";
import firebase from "firebase";
import Swal from "sweetalert2";
import { deepClone } from "helper-methods";
import { showToast } from "helper-methods";
import { generateAppTitle } from "helper-methods";
import { createUser } from "http-calls";
import { getClinicIdBasedOnDomain } from "helper-methods";
import { addClinicStaff } from "http-calls";

const initialState = {
  formFields: {
    email: {
      value: "",
      isValid: false,
      isDirty: false,
      errorText: "",
      isRequired: true,
    },
    password: {
      value: "",
      isValid: false,
      isDirty: false,
      errorText: "",
      isRequired: true,
    },
    confirmPassword: {
      value: "",
      isValid: false,
      isDirty: false,
      errorText: "",
      isRequired: true,
    },
    phone: {
      value: "",
      isValid: false,
      isDirty: false,
      errorText: "",
      isRequired: true,
    },
  },
  isFormValid: false,
  redirectTo: null,
};

class RegisterForm extends Component {
  state = deepClone(initialState);

  _markAsDirty = (fieldName) => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      formFields[fieldName].isDirty = true;
      this.setState({ formFields }, () => {
        this._validateForm();
        resolve();
      });
    });
  };

  _updateFieldValue = (fieldName, value) => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      formFields[fieldName].value = value;
      this.setState({ formFields }, () => {
        if (formFields[fieldName].isDirty) {
          // Validate
          this._validateForm();
        }
        resolve();
      });
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        const field = formFields[fieldName];
        field.isValid = true;
        if (field.isRequired) {
          switch (fieldName) {
            case "email": {
              var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (!emailRegex.test(String(field.value).toLowerCase())) {
                field.isValid = false;
                field.errorText = "Please provide a valid email address";
                isFormValid = false;
              }
              break;
            }
            case "password": {
              if (!field.value || field.value.length === 0) {
                field.isValid = false;
                field.errorText = "Please provide a password";
                isFormValid = false;
              } else if (!(field.value && field.value.length > 6)) {
                field.isValid = false;
                field.errorText = "Minimum charecter should be 6";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (!field.value || field.value.toString().length < 6) {
                field.isValid = false;
                field.errorText = "Please provide a valid phone";
                isFormValid = false;
              }
              break;
            }
            case "confirmPassword": {
              if (
                formFields.password.value &&
                formFields.password.value.length > 0
              ) {
                if (!field.value || field.value.length === 0) {
                  field.isValid = false;
                  field.errorText = "Please confirm the password";
                  isFormValid = false;
                } else {
                  if (field.value !== formFields.password.value) {
                    field.isValid = false;
                    field.errorText = "Password didn't match";
                    isFormValid = false;
                  }
                }
              }
              break;
            }
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _navigateTo = (path, e = null) => {
    if (e) {
      e.preventDefault();
    }
    this.props.history.push(path);
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _generateLoginPayload = (formFields) => {
    const payload = {};
    for (let field in formFields) {
      payload[field] = formFields[field].value;
    }
    return payload;
  };

  _firebaseEmailLogin = async (email, password) => {
    await firebase.auth().signInWithEmailAndPassword(email, password);
  };

  _validateAndSubmit = async (e) => {
    e.preventDefault();
    await this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid, redirectTo } = this.state;
    if (isFormValid) {
      this.props.showLoader("Creating your account");
      try {
        await firebase
          .auth()
          .createUserWithEmailAndPassword(
            formFields.email.value,
            formFields.password.value
          );
        if (firebase.auth().currentUser) {
          firebase.auth().currentUser.sendEmailVerification();
          await firebase
          .auth()
          .signInWithEmailAndPassword(
            formFields.email.value,
            formFields.password.value
          );
          await createUser({
            "CellNumber": `+1${formFields.phone.value.replace('+1', '')}`,
            "ClinicID": getClinicIdBasedOnDomain()
          })
          await addClinicStaff({
            "Username": formFields.email.value,
            "RoleTypeID": 3,
            "ClinicID": getClinicIdBasedOnDomain()
          })
          FirebaseAuthService.logout();
        }
        this.props.hideLoader();
        Swal.fire({
          icon: "info",
          title: "Account created successfully",
          text:
            "Please verify your account first using the link we have mailed you",
        });
        this.props.switchTo("login");
      } catch (error) {
        console.log("error :>> ", error);
        this.props.hideLoader();
        showToast(
          error.message
            ? error.message
            : "Unable to create account, please try again",
          "error"
        );
      }
    }
  };

  _loginWithGithub = async () => {
    var provider = new firebase.auth.GithubAuthProvider();
    provider.addScope("user:email");
    this.props.showLoader(" ");
    try {
      const response = await firebase.auth().signInWithPopup(provider);
      if (response && response.user) {
        this.props.updateUserData({
          email: response.user.email,
          name: response.user.displayName,
        });
      }
      // await this._createNewProjectIfDataAvailable(newProject);
      this.props.hideLoader();
      this.props.history.push("/my-projects");
    } catch (error) {
      console.log("error :>> ", error);
      if (error.code === "auth/account-exists-with-different-credential") {
        showToast(
          "Account created with different social provider. Please continue with that one",
          "error",
          {
            hideAfter: 5,
          }
        );
      } else {
        showToast(error.message, "error");
      }
      this.props.hideLoader();
    }
  };

  _loginWithFacebook = async () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope("email");
    this.props.showLoader(" ");
    try {
      const response = await firebase.auth().signInWithPopup(provider);
      if (response && response.user) {
        this.props.updateUserData({
          email: response.user.email,
          name: response.user.displayName,
        });
      }
      // await this._createNewProjectIfDataAvailable(newProject);
      this.props.hideLoader();
      this.props.history.push("/my-projects");
    } catch (error) {
      console.log("error :>> ", error);
      if (error.code === "auth/account-exists-with-different-credential") {
        showToast(
          "Account created with different social provider. Please continue with that one",
          "error",
          {
            hideAfter: 5,
          }
        );
      } else {
        showToast(error.message, "error");
      }
      this.props.hideLoader();
    }
  };

  _loginWithTwitter = async () => {
    var provider = new firebase.auth.TwitterAuthProvider();
    this.props.showLoader(" ");
    try {
      const response = await firebase.auth().signInWithPopup(provider);
      if (response && response.user) {
        this.props.updateUserData({
          email: response.user.email,
          name: response.user.displayName,
        });
      }
      // await this._createNewProjectIfDataAvailable(newProject);
      this.props.hideLoader();
      this.props.history.push("/my-projects");
    } catch (error) {
      console.log("error :>> ", error);
      if (error.code === "auth/account-exists-with-different-credential") {
        showToast(
          "Account created with different social provider. Please continue with that one",
          "error",
          {
            hideAfter: 5,
          }
        );
      } else {
        showToast(error.message, "error");
      }
      this.props.hideLoader();
    }
  };

  _loginWithLinkedIn = async () => {
    const linkedInConfig = {
      response_type: "code",
      client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_LINKEDIN_LOGIN_REDIRECT_URL,
      scope: "r_liteprofile%20r_emailaddress%20w_member_social",
    };
    window.location = `https://www.linkedin.com/uas/oauth2/authorization?response_type=${linkedInConfig.response_type}&client_id=${linkedInConfig.client_id}&redirect_uri=${linkedInConfig.redirect_uri}&scope=${linkedInConfig.scope}`;
  };

  _navigateTo = (path, e = null) => {
    if (e) {
      e.preventDefault();
    }
    this.props.history.push(path);
  };

  _loginWithGoogle = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("email");
    this.props.showLoader(" ");
    try {
      const response = await firebase.auth().signInWithPopup(provider);
      if (response && response.user) {
        this.props.updateUserData({
          email: response.user.email,
          name: response.user.displayName,
        });
      }
      this.props.hideLoader();
      this.props.history.push("/admin");
    } catch (error) {
      console.log("error :>> ", error);
      if (error.code === "auth/account-exists-with-different-credential") {
        showToast(
          "Account created with different social provider. Please continue with that one",
          "error",
          {
            hideAfter: 5,
          }
        );
      } else {
        showToast(error.message, "error");
      }
      this.props.hideLoader();
    }
  };

  render() {
    const { formFields } = this.state;
    const { switchTo } = this.props;
    return (
      <>
        <div className="formPart">
        <h3 className="logo">{generateAppTitle()}</h3>
          <h2>Get Started Now!</h2>
          <div className="formWrapper">
            <div className="inputWrapper">
              <p className="label">Email</p>
              <input
                type="text"
                autoComplete="off"
                value={formFields.email.value}
                onChange={(e) =>
                  this._updateFieldValue("email", e.target.value)
                }
                onBlur={() => this._markAsDirty("email")}
              />
              <div className="fieldError">
                {formFields.email.isDirty && !formFields.email.isValid
                  ? formFields.email.errorText
                  : ""}
              </div>
            </div>
            <div className="inputWrapper">
              <p className="label">Password</p>
              <input
                type="password"
                autoComplete="off"
                value={formFields.password.value}
                onChange={(e) =>
                  this._updateFieldValue("password", e.target.value)
                }
                onBlur={() => this._markAsDirty("password")}
              />
              <div className="fieldError">
                {formFields.password.isDirty && !formFields.password.isValid
                  ? formFields.password.errorText
                  : ""}
              </div>
            </div>
            <div className="inputWrapper">
              <p className="label">Repeat Password</p>
              <input
                type="password"
                autoComplete="off"
                value={formFields.confirmPassword.value}
                onChange={(e) =>
                  this._updateFieldValue("confirmPassword", e.target.value)
                }
                onBlur={() => this._markAsDirty("confirmPassword")}
              />
              <div className="fieldError">
                {formFields.confirmPassword.isDirty &&
                !formFields.confirmPassword.isValid
                  ? formFields.confirmPassword.errorText
                  : ""}
              </div>
            </div>

            <div className="inputWrapper">
              <p className="label">Phone Number</p>
              <input 
                type="number" 
                autoComplete="off" 
                step="0.01" 
                value={formFields.phone.value}
                onChange={(e) =>
                  this._updateFieldValue("phone", e.target.value)
                }
                onBlur={() => this._markAsDirty("phone")}
              />
              <div className="fieldError">
                {formFields.phone.isDirty && !formFields.phone.isValid
                  ? formFields.phone.errorText
                  : ""}
              </div>
            </div>
            {/* <div className="inputWrapper">
              <p className="label">Clinic Location</p>
              <input type="text" autoComplete="off" />
            </div> */}
            <div className="actionBar jcCenter">
              <button
                className="signin"
                onClick={(e) => this._validateAndSubmit(e)}
              >
                Sign Up
              </button>
            </div>
            <h5 className="connectWithLabel">Or connect with</h5>
            <div className="socialAuthWrapper">
              <button
                className="socialBtn googleBtn"
                onClick={this._loginWithGoogle}
              >
                <i class="fa fa-google" aria-hidden="true"></i>
              </button>
              <button
                className="socialBtn facebookBtn"
                onClick={this._loginWithFacebook}
              >
                <i class="fa fa-facebook" aria-hidden="true"></i>
              </button>
              <button
                className="socialBtn githubBtn"
                onClick={this._loginWithGithub}
              >
                <i class="fa fa-github-alt" aria-hidden="true"></i>
              </button>
              <button
                className="socialBtn twitterBtn"
                onClick={this._loginWithTwitter}
              >
                <i class="fa fa-twitter" aria-hidden="true"></i>
              </button>
            </div>
            <div className="bottomBar" onClick={(e) => switchTo("login")}>
              Already have an account? <span>Sign In</span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateUserData: (userData) => dispatch(updateUserData(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
