import Header from "modules/general/containers/header/header";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ClinicSystemSettingsPage from "./pages/clinic-system-settings-page/clinic-system-settings-page";
import AccessGuard from 'guards/access-guard';

class ClinicSystemsSettingsModule extends Component {
  state = {};

  render() {
    return (
      <>
      <AccessGuard accessLevel={["SuperAdmin", "ClinicAdmin"]}>
        <Header>
          <Switch>
            <Route
              path="/manage-clinic-system-settings"
              component={ClinicSystemSettingsPage}
            />
            <Route
              path="*"
              render={() => <Redirect to="/manage-clinic-system-settings" />}
            />
          </Switch>
        </Header>
        </AccessGuard>
      </>
    );
  }
}

export default ClinicSystemsSettingsModule;
