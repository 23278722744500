import React, { Component } from "react";
import Modal from "react-awesome-modal";
import "./register-patient-prompt.scss";
import CustomInput from "components/CustomInput/CustomInput";
import MaskedInput from "react-input-mask";
import { deepClone } from "helper-methods";
import moment from "moment";
import { submitPatientData } from "http-calls";
import Swal from "sweetalert2";
import { showToast } from "helper-methods";
import { checkIfPatientExists } from "http-calls";
import ClinicDataParser from '../../../../utils/clinic-data-parser';

const initialState = {
  formFields: {
    firstName: {
      value: "",
      isValid: true,
      isDirty: false,
      isRequired: true,
    },
    lastName: {
      value: "",
      isValid: true,
      isDirty: false,
      isRequired: true,
    },
    phoneNumber: {
      value: "",
      isValid: false,
      isDirty: false,
      isRequired: true,
    },
    visitReason: {
      value: "",
      isValid: false,
      isDirty: false,
      isRequired: true,
    },
    dateOfBirth: {
      value: "",
      isValid: false,
      isDirty: false,
      isRequired: true,
    },
  },
  isFormValid: false,
  generalError: "",
  isLoaderActive: false,
};

class RegisterPatientPrompt extends Component {
  state = deepClone(initialState);

  componentDidUpdate(prevProps) {
    if (prevProps.isVisible && !this.props.isVisible) {
      this._resetState();
    }
  }

  _resetState = () => {
    this.setState(deepClone(initialState));
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields }, () => {
      if (formFields[fieldName].isDirty) {
        // Validate
        this._validateForm();
      }
    });
  };

  _isDateValid = (dateOfBirth) => {
    let unMaskedDob = dateOfBirth.replace(/_/g, "");
    unMaskedDob = unMaskedDob.replace(/\//g, "");
    if (unMaskedDob.length !== 8) {
      return false;
    }
    if (!moment(dateOfBirth, "MM/DD/YYYY").isValid()) {
      return false;
    }
    if (!moment(unMaskedDob, "MM/DD/YYYY").isBefore(moment())) {
      return false;
    }
    if (
      !moment(unMaskedDob, "MM/DD/YYYY").isAfter(
        moment("31/12/1918", "DD/MM/YYYY")
      )
    ) {
      return false;
    }
    return true;
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { visitReasons } = this.props;

      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "firstName": {
            if (formFields.firstName.value.length >= 1) {
              formFields.firstName.isValid = true;
            } else {
              formFields.firstName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "lastName": {
            if (formFields.lastName.value.length >= 1) {
              formFields.lastName.isValid = true;
            } else {
              formFields.lastName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "phoneNumber": {
            if (formFields.phoneNumber.value.length === 10) {
              formFields.phoneNumber.isValid = true;
            } else {
              formFields.phoneNumber.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "visitReason": {
            if (
              formFields.visitReason.value.length >= 1 ||
              !(visitReasons && visitReasons.length)
            ) {
              formFields.visitReason.isValid = true;
            } else {
              formFields.visitReason.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "dateOfBirth": {
            if (
              formFields.dateOfBirth.value &&
              formFields.dateOfBirth.value.length &&
              this._isDateValid(formFields.dateOfBirth.value)
            ) {
              formFields.dateOfBirth.isValid = true;
            } else {
              formFields.dateOfBirth.isValid = false;
              isFormValid = false;
            }
            break;
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _updatePhoneNumber = (maskedValue) => {
    let unMaskedPhoneNumber = maskedValue.replace("(", "");
    unMaskedPhoneNumber = unMaskedPhoneNumber.replace(")", "");
    unMaskedPhoneNumber = unMaskedPhoneNumber.replace(" ", "");
    unMaskedPhoneNumber = unMaskedPhoneNumber.replace("-", "");
    unMaskedPhoneNumber = unMaskedPhoneNumber.split("_").join("");
    this._updateFieldValue("phoneNumber", unMaskedPhoneNumber);
  };

  _parseCallNumber = (patientAddResponse) => {
    const parts = patientAddResponse.split("CallNumber: ");
    return parts[1];
  };

  /**
   * Returns patient's callnumber if already checked in
   * Otherwise returns null
   */
  _tryToFetchPatientCallNumber = async (phoneNumber) => {
    try {
      const response = await checkIfPatientExists(
        phoneNumber,
        this.props.clinicId
      );
      if (response.status === 200) {
        // Already exists
        const callNumber = this._parseCallNumber(response.data);
        return callNumber;
      } else {
        // No registrations exists with the number
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  _addPatient = async (e) => {
    const userLabel = ClinicDataParser.getUserLabel();
    e.preventDefault();
    await this._makeAllFieldDirty();
    await this._validateForm();
    let { formFields, isFormValid } = this.state;
    if (isFormValid) {
      // First check if patient already checked in or not
      this._toggleLoader(true);
      const callNumber = await this._tryToFetchPatientCallNumber(
        formFields.phoneNumber.value
      );
      if (callNumber) {
        // Already checked in
        // Show alert
        this._toggleLoader(false);
        Swal.fire({
          icon: "error",
          title: `${userLabel} has an active check-in`,
          text: `A check in already exists with the same mobile number, here is the callnumber ${callNumber}`,
          onClose: () => {
            // Reset form
            this._resetState();
          },
        });
      } else {
        // Not checked in, so process the registration
        const { visitReasons } = this.props;
        try {
          const patient = {
            FirstName: formFields.firstName.value,
            LastName: formFields.lastName.value,
            MobileNumber: formFields.phoneNumber.value,
            DOB: formFields.dateOfBirth.value,
            ClinicID: this.props.clinicId,
          };
          if (visitReasons && visitReasons.length) {
            patient["VisitType"] = formFields.visitReason.value;
          }
          const patientAddResponse = await submitPatientData(patient);
          this._toggleLoader(false);
          showToast(`${userLabel} added successfully`, "success", {
            positon: "bottom-center",
          });
          this.props.onDismiss();
        } catch (error) {
          this._toggleLoader(false);
          console.log("error :>> ", error);
          this.setState({ generalError: `Failed to add ${userLabel}` }, () => {});
        }
      }
    }
  };

  _toggleLoader = (status) => {
    this.setState({ isLoaderActive: status });
  };

  render() {
    const {
      isVisible = true,
      onDismiss = () => {},
      visitReasons = [],
    } = this.props;
    const { formFields, generalError, isLoaderActive } = this.state;

    const userLabel = ClinicDataParser.getUserLabel();

    return (
      <>
        <Modal
          visible={isVisible}
          width="600"
          height="80%"
          effect="fadeInUp"
          onClickAway={onDismiss}
        >
          <div className="patientRegistrationPromptWrapper">
            <div className="modalHeader">
              <button className="dismissBtn" onClick={onDismiss}>
                Close
              </button>
            </div>
            <h3>Add new {userLabel.toLowerCase()}</h3>
            <div className="promptFormWrapper">
              <div className="promptFormRow">
                <div className="promptInputWrapper">
                  <div className="inputWrapper">
                    <CustomInput
                      labelText="First Name"
                      id="float"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: formFields.firstName.value,
                        onChange: (e) =>
                          this._updateFieldValue("firstName", e.target.value),
                        onBlur: () => this._markAsDirty("firstName"),
                      }}
                    />
                    <div className="errorText">
                      {!formFields.firstName.isValid &&
                      formFields.firstName.isDirty
                        ? "Please provide valid first name"
                        : ""}
                    </div>
                  </div>
                  <div className="inputWrapper">
                    <CustomInput
                      labelText="Last Name"
                      id="float"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: formFields.lastName.value,
                        onChange: (e) =>
                          this._updateFieldValue("lastName", e.target.value),
                        onBlur: () => this._markAsDirty("lastName"),
                      }}
                    />
                    <div className="errorText">
                      {!formFields.lastName.isValid &&
                      formFields.lastName.isDirty
                        ? "Please provide valid last name"
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="promptFormRow">
                <div className="promptInputWrapper phoneInput">
                  <div className="phoneLabel">Phone Number: </div>
                  <MaskedInput
                    {...this.props}
                    // alwaysShowMask
                    value={formFields.phoneNumber.value}
                    onChange={(e) => this._updatePhoneNumber(e.target.value)}
                    placeholder={"(___) ___-____"}
                    mask="(999) 999-9999"
                    onBlur={() => this._markAsDirty("phoneNumber")}
                  />
                </div>
              </div>
              <div className="promptFormRow">
                <div className="promptInputWrapper dobInput">
                  <div className="dobLabel">Date of birth: </div>
                  <MaskedInput
                    {...this.props}
                    mask="99/99/9999"
                    // alwaysShowMask
                    value={formFields.dateOfBirth.value}
                    onChange={(e) =>
                      this._updateFieldValue("dateOfBirth", e.target.value)
                    }
                    placeholder={"MM/DD/YYYY"}
                    onBlur={(e) => this._markAsDirty("dateOfBirth")}
                  />
                </div>
              </div>
              <div className="outerErrorText">
                {!formFields.dateOfBirth.isValid &&
                formFields.dateOfBirth.isDirty
                  ? "Please provide valid Date of Birth"
                  : ""}
              </div>
              <div className="visitReasonWrapper">
                <h4>Select a reason of visit</h4>
                <div className="reasonRow">
                  {visitReasons.map((visitReason, visitReasonIndex) => (
                    <div
                      className={
                        "reason " +
                        (formFields.visitReason.value === visitReason
                          ? " selected"
                          : "")
                      }
                      onClick={(e) =>
                        this._updateFieldValue("visitReason", visitReason)
                      }
                      key={visitReasonIndex}
                    >
                      {visitReason}
                    </div>
                  ))}
                </div>
              </div>
              <div className="reasonErrorText">
                {" "}
                {!formFields.visitReason.isValid &&
                formFields.visitReason.isDirty
                  ? "Please select a reason to continue"
                  : ""}
              </div>

              <div className="bottomAction">
                {isLoaderActive ? (
                  <div className="loader"></div>
                ) : (
                  <button className="submitBtn" onClick={this._addPatient}>
                    Add {userLabel}
                  </button>
                )}
              </div>
              <div className="generalError">{generalError}</div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default RegisterPatientPrompt;
