import firebase from "firebase";
import jwtDecode from "jwt-decode";

const FirebaseAuthService = {
  getFirebaseClientToken: () => {
    return new Promise(async (resolve, reject) => {
      if (firebase.auth().currentUser) {
        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true);
        resolve(idToken);
      } else {
        // Might be firebase is not initialized, so wait 1 sec and attempt again
        setTimeout(async () => {
          if (firebase.auth().currentUser) {
            const idToken = await firebase
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true);
            resolve(idToken);
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject();
          }
        }, 1000);
      }
    });
  },
  isUserVerified: async () => {
    const idToken = await FirebaseAuthService.getFirebaseClientToken();
    const decoded = jwtDecode(idToken);
    if (
      decoded &&
      decoded.firebase &&
      decoded.firebase.sign_in_provider &&
      decoded.firebase.sign_in_provider === "password"
    ) {
      if (!decoded.email_verified) {
        // Manual signin, email not verified
        // So logout and send to no access page
        return false;
      }
    }
    return true;
  },
  logout: () => {
    firebase.auth().signOut();
  },
};

export default FirebaseAuthService;
