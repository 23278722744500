import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { deepClone } from "../../../../helper-methods";
import "./clinic-editor.scss";
import ColorPicker from "material-ui-color-picker";
import ImageUploader from "../../../general/components/image-uploader/image-uploader";
import { updateClinic, uploadFile } from "../../../../http-calls/index";

const initialState = {
  formFields: {
    clinicName: {
      value: "",
      isValid: false,
      isDirty: false,
      isRequired: true,
    },
    userLabel: {
      value: "",
      isValid: false,
      isDirty: false,
      isRequired: true,
    },
    clinicLocation: {
      value: "",
      isValid: false,
      isDirty: false,
      isRequired: true,
    },
    clinicColor: {
      value: "#8C0000",
      isValid: false,
      isDirty: false,
      isRequired: true,
    },
  },
  isFormValid: false,
  isCustomizatonEnabled: false,
  fileToUpload: null,
  fileLink: "",
};

class ClinicEditor extends Component {
  state = deepClone(initialState);

  componentDidMount() {
    if (this.props.editMode) {
      this._initializeFields();
    } else {
      this._resetFields();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isVisible && this.props.isVisible) {
      // Opened
      if (this.props.editMode) {
        this._initializeFields();
      } else {
        this._resetFields();
      }
    }
  }

  _initializeFields = () => {
    if (this.props.selectedClinic && Object.keys(this.props.selectedClinic)) {
      const { formFields } = this.state;
      formFields.clinicName.value = this.props.selectedClinic.Name;
      formFields.clinicLocation.value = this.props.selectedClinic.Location;
      formFields.clinicColor.value = this.props.selectedClinic.themeColor || "";
      formFields.userLabel.value = this.props.selectedClinic.Userlabel || "";
      let fileLink = this.props.selectedClinic.Logo;
      let isCustomizatonEnabled = false;
      if (fileLink?.length || formFields.clinicColor.value?.length) {
        isCustomizatonEnabled = true;
      }
      this.setState({ formFields, fileLink, isCustomizatonEnabled });
    }
  };

  _resetFields = () => {
    this.setState(deepClone(initialState));
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields }, () => {
      if (formFields[fieldName].isDirty) {
        // Validate
        this._validateForm();
      }
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "clinicName": {
            if (formFields.clinicName.value.length >= 1) {
              formFields.clinicName.isValid = true;
            } else {
              formFields.clinicName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "clinicLocation": {
            if (formFields.clinicLocation.value.length >= 1) {
              formFields.clinicLocation.isValid = true;
            } else {
              formFields.clinicLocation.isValid = false;
              isFormValid = false;
            }
            break;
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _save = async (e) => {
    e.preventDefault();
    await this._makeAllFieldDirty();
    await this._validateForm();
    const {
      formFields,
      isFormValid,
      isCustomizatonEnabled,
      fileLink,
      fileToUpload,
    } = this.state;
    if (isFormValid) {
      try {
        // Format data
        if (this.props.editMode) {
          const updatedClinic = {
            ClinicID: this.props.selectedClinic.ClinicID,
            Name: formFields.clinicName.value,
            Location: formFields.clinicLocation.value,
          };
          if (isCustomizatonEnabled) {
            updatedClinic.ThemeColor = formFields.clinicColor.value;
            updatedClinic.Userlabel = formFields.userLabel.value;
            let creatorPhotoLink = fileLink;
            if (fileToUpload) {
              this.props.showLoader();
              // Process upload first
              creatorPhotoLink = await this.fileUpload(fileToUpload);
            }
            updatedClinic.Logo = creatorPhotoLink;
          } else {
            updatedClinic.Logo = null;
            updatedClinic.ThemeColor = null;
            updatedClinic.Userlabel = "";
          }
          this.props.onClinicUpdate(updatedClinic);
        } else {
          // Create mode
          const newClinic = {
            Name: formFields.clinicName.value,
            Location: formFields.clinicLocation.value,
          };
          this.props.onCreateNewClinic(newClinic);
        }
      } catch (error) {}
    }
  };

  _discard = () => {
    this.props.onDiscard();
  };

  _toggleCustomization = (isCustomizatonEnabled) => {
    this.setState({ isCustomizatonEnabled });
  };

  _onFileChange = (file) => {
    this.setState({ fileToUpload: file });
  };

  _onFileClear = () => {
    this.setState({ fileToUpload: null, fileLink: "" });
  };

  fileUpload = async (file) => {
    const { fileToUpload } = this.state;
    const formData = new FormData();
    formData.append("file", fileToUpload);
    try {
      const response = await uploadFile(formData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return "";
    }
  };

  render() {
    const { formFields, isCustomizatonEnabled, fileLink } = this.state;
    return (
      <>
        <Modal
          visible={this.props.isVisible}
          width="550"
          height="600"
          effect="fadeInUp"
          onClickAway={this._discard}
        >
          <div id="clinicEditorFormWrapper">
            <div className="crossWrapper" onClick={this._discard}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            <h3>
              {this.props.editMode ? "Update clinic details" : "Add new clinic"}
            </h3>
            <div className="promptFormWrapper">
              <CustomInput
                labelText="Clinic Name"
                id="float"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: formFields.clinicName.value,
                  onChange: (e) =>
                    this._updateFieldValue("clinicName", e.target.value),
                  onBlur: () => this._markAsDirty("clinicName"),
                }}
              />
              <p className="formErrorText">
                {formFields.clinicName.isDirty && !formFields.clinicName.isValid
                  ? "Please provide valid clinic name"
                  : ""}
              </p>
              <CustomInput
                labelText="Clinic Location"
                id="float"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: formFields.clinicLocation.value,
                  onChange: (e) =>
                    this._updateFieldValue("clinicLocation", e.target.value),
                  onBlur: () => this._markAsDirty("clinicLocation"),
                }}
              />
              <p className="formErrorText">
                {formFields.clinicLocation.isDirty &&
                !formFields.clinicLocation.isValid
                  ? "Please provide valid clinic location"
                  : ""}
              </p>
              {this.props.editMode ? (
                <>
                  <div
                    className="customSettingsToggle"
                    onClick={(e) =>
                      this._toggleCustomization(!isCustomizatonEnabled)
                    }
                  >
                    <input type="checkbox" checked={isCustomizatonEnabled} />{" "}
                    Customize clinic ui
                  </div>
                  {isCustomizatonEnabled ? (
                    <>
                      <div className="colorPickerWrapper">
                        <ColorPicker
                          style={{ width: "100%" }}
                          // defaultValue={formFields[field].value}
                          value={formFields.clinicColor.value}
                          onChange={(value) => {
                            this._updateFieldValue("clinicColor", value);
                          }}
                          floatingLabelText="Clinic Theme Color"
                        />
                      </div>
                      <div className="clinicLogoUploaderWrapper">
                        <p className="mb-2">Custom logo</p>
                        <ImageUploader
                          selectedFileLink={fileLink}
                          onFileChange={this._onFileChange}
                          onFileClear={this._onFileClear}
                        />
                      </div>
                      <CustomInput
                        labelText="User Label"
                        id="float"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeHolder: "Default is 'User'",
                          value: formFields.userLabel.value,
                          onChange: (e) =>
                            this._updateFieldValue("userLabel", e.target.value),
                          onBlur: () => this._markAsDirty("userLabel"),
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              <div className="formActionWrapper">
                <Button
                  color="success"
                  className="saveButton"
                  onClick={this._save}
                >
                  {this.props.editMode ? "Save" : "Create"}
                </Button>
                <Button
                  color="danger"
                  className="discardButton"
                  onClick={this._discard}
                >
                  Discard
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default ClinicEditor;
