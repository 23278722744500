import React, { Component } from "react";
import { showLoader } from "redux/actions/loader-data";
import { hideLoader } from "redux/actions/loader-data";
import { updateUserData } from "redux/actions/user-data";
import { connect } from "react-redux";
import FirebaseAuthService from "firebase-auth-service";
import firebase from "firebase";
import Swal from "sweetalert2";
import { deepClone } from "helper-methods";
import { showToast } from "helper-methods";
import { generateAppTitle } from "helper-methods";


const initialState = {
  formFields: {
    email: {
      value: "",
      isValid: false,
      isDirty: false,
      errorText: "",
      isRequired: true,
    },
  },
  isFormValid: false,
  redirectTo: null,
};

class ForgetPasswordForm extends Component {
  state = deepClone(initialState);

  _markAsDirty = (fieldName) => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      formFields[fieldName].isDirty = true;
      this.setState({ formFields }, () => {
        this._validateForm();
        resolve();
      });
    });
  };

  _updateFieldValue = (fieldName, value) => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      formFields[fieldName].value = value;
      this.setState({ formFields }, () => {
        if (formFields[fieldName].isDirty) {
          // Validate
          this._validateForm();
        }
        resolve();
      });
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        const field = formFields[fieldName];
        field.isValid = true;
        if (field.isRequired) {
          switch (fieldName) {
            case "email": {
              var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (!emailRegex.test(String(field.value).toLowerCase())) {
                field.isValid = false;
                field.errorText = "Please provide a valid email address";
                isFormValid = false;
              }
              break;
            }
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _generateLoginPayload = (formFields) => {
    const payload = {};
    for (let field in formFields) {
      payload[field] = formFields[field].value;
    }
    return payload;
  };

  _validateAndSubmit = async (e) => {
    e.preventDefault();
    await this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid, redirectTo } = this.state;
    if (isFormValid) {
      this.props.showLoader("Please wait");
      try {
        await firebase
          .auth()
          .sendPasswordResetEmail(
            formFields.email.value
          );
          this.props.hideLoader();
          showToast(
            "Password reset link sent",
            "success"
          );
          this.props.switchTo("login")
      } catch (error) {
        console.log("error :>> ", error);
        this.props.hideLoader();
        showToast(
          error.message
            ? error.message
            : "Unable to send reset link, please try again",
          "error"
        );
      }
    }
  };

  _navigateTo = (path, e = null) => {
    if (e) {
      e.preventDefault();
    }
    this.props.history.push(path);
  };

  render() {
    const { formFields } = this.state;
    const { switchTo } = this.props;

    return (
      <>
        <div className="formPart">
        <h3 className="logo">{generateAppTitle()}</h3>
          <h2>Reset Passowrd</h2>
          <div className="formWrapper">
            <div className="inputWrapper">
              <p className="label">Registered Email</p>
              <input type="text" />
            </div>
            <div className="actionBar jcCenter">
              <button className="signin">Request Link</button>
            </div>
            <div className="bottomBar" onClick={e => switchTo("login")}>
              Back to <span>Login</span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateUserData: (userData) => dispatch(updateUserData(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordForm);
