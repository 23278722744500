import React, { Component } from "react";
import "./call-to-action-prompt.scss";
import MaskedInput from "react-input-mask";
import Loader from "react-loader-spinner";
import { deepClone } from "helper-methods";
import { connectVoiceCall } from "http-calls";
import Swal from "sweetalert2";
import { EventEmitter } from "../../../../utils/event-emitter";

const initialState = {
  isModalVisible: false,
  formFields: {
    phoneNumber: {
      value: "",
      isValid: false,
      isDirty: false,
      isRequired: true,
    },
  },
  isFormValid: false,
  generalError: "",
  isLoaderActive: false,
  currentUserPhoneNumber: "",
  currentClinicId: -1,
};

class CallToActionPrompt extends React.Component {
  state = deepClone(initialState);

  componentDidMount() {
    this._registerEvents();
  }

  _registerEvents = () => {
    EventEmitter.subscribe("show-dailer", ({ phone, clinicId }) => {
      this.setState({
        currentUserPhoneNumber: phone,
        currentClinicId: clinicId,
        isModalVisible: true,
      });
    });
    EventEmitter.subscribe("hide-dailer", () => {
      this._hideModal();
    });
  };

  _hideModal = () => {
    // Reset state
    this.setState(deepClone(initialState));
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "phoneNumber": {
            if (formFields.phoneNumber.value.length === 10) {
              formFields.phoneNumber.isValid = true;
            } else {
              formFields.phoneNumber.isValid = false;
              isFormValid = false;
            }
            break;
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields }, () => {
      if (formFields[fieldName].isDirty) {
        // Validate
        this._validateForm();
      }
    });
  };

  _updatePhoneNumber = (maskedValue) => {
    console.log("maskedValue :>> ", maskedValue);
    let unMaskedPhoneNumber = maskedValue.replace("(", "");
    unMaskedPhoneNumber = unMaskedPhoneNumber.replace(")", "");
    unMaskedPhoneNumber = unMaskedPhoneNumber.replace(" ", "");
    unMaskedPhoneNumber = unMaskedPhoneNumber.replace("-", "");
    unMaskedPhoneNumber = unMaskedPhoneNumber.split("_").join("");
    this._updateFieldValue("phoneNumber", unMaskedPhoneNumber);
  };

  _toggleLoader = (status) => {
    this.setState({ isLoaderActive: status });
  };

  _dialNumber = async (e) => {
    e.preventDefault();
    await this._makeAllFieldDirty();
    await this._validateForm();
    let { formFields, isFormValid } = this.state;
    if (isFormValid) {
      // First check if patient already checked in or not
      await this._call(formFields.phoneNumber.value);
    }
  };

  _callMe = async () => {
    const { staffInfo } = this.props;
    if (staffInfo?.length && staffInfo[0].CellNumber?.toString()?.length) {
      await this._call(staffInfo[0].CellNumber.replace("+1", ""));
    } else {
      Swal.fire({
        icon: "error",
        title: `No phone number found`,
        text: `Please update your profile with a valid phone number`,
        onClose: () => {
          // Reset form
          // this._resetState();
        },
      });
    }
  };

  _forwardToSuicideHotline = async () => {
    // await this._call();
  };

  _call = async (number) => {
    this._toggleLoader(true);
    const { currentUserPhoneNumber, currentClinicId } = this.state;
    try {
      await connectVoiceCall({
        ToPhoneNumber: currentUserPhoneNumber,
        ConnectToNumber: `+1${number}`,
        ClinicID: currentClinicId,
      });
      Swal.fire({
        icon: "success",
        title: `Call connected`,
        onClose: () => {
          // Reset form
          this._hideModal();
        },
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `Unable to initiate call`,
        text: `Please check the number and try again`,
        onClose: () => {
          // Reset form
          // this._resetState();
        },
      });
    }
    this._toggleLoader(false);
  };

  render() {
    const {
      formFields,
      generalError,
      isLoaderActive,
      isModalVisible,
    } = this.state;

    if (isModalVisible) {
      return (
        <>
          <div className="callToActionPromptWrapper">
            <div className="overlay">
              <div className="modalWrapper">
                <div className="modalBody">
                  {!isLoaderActive ? (
                    <>
                      <button className="dismissBtn" onClick={this._hideModal}>
                        Dismiss
                      </button>
                      <div className="dialerWrapper">
                        <MaskedInput
                          // alwaysShowMask
                          value={formFields.phoneNumber.value}
                          onChange={(e) =>
                            this._updatePhoneNumber(e.target.value)
                          }
                          placeholder={"(___) ___-____"}
                          mask="(999) 999-9999"
                          // onBlur={() => this._markAsDirty("phoneNumber")}
                        />
                        <button onClick={this._dialNumber}>Dial</button>
                      </div>
                      <div className="errorText">
                        {!formFields.phoneNumber.isValid &&
                        formFields.phoneNumber.isDirty
                          ? "Please enter valid phone number"
                          : ""}
                      </div>
                      <div className="hotKeysWrapper">
                        <button
                          className="hotKey callMeBtn"
                          onClick={this._callMe}
                        >
                          Call Me
                        </button>
                        <button
                          className="hotKey suicideBtn"
                          onClick={this._forwardToSuicideHotline}
                        >
                          Forward to suicide hotline
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="loaderWrapper">
                        <Loader
                          type="ThreeDots"
                          color="black"
                          height={30}
                          width={30}
                        />
                        <h3>Initiating the call</h3>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
}

export default CallToActionPrompt;
