import React, { Component } from "react";

class QueueMapPointer extends Component {
  state = {
    isExpanded: false,
  };

  _toggleMode = (isExpanded) => {
    this.setState({ isExpanded });
  };

  _getPointerTextClass = () => {
    const { isExpanded } = this.state;
    if (isExpanded) {
      return "pointerText active";
    } else {
      return "pointerText";
    }
  };

  render() {
    const { pointerIndex, text } = this.props;

    return (
      <>
        <div
          className={`mapPointOuterWrapper point${pointerIndex}`}
          onMouseEnter={(e) => this._toggleMode(true)}
          onMouseLeave={(e) => this._toggleMode(false)}
        >
          <div className="mapPointInnerWrapper">
            <div className="mapPointer">{pointerIndex}</div>
            <div className={this._getPointerTextClass()}>{text}</div>
          </div>
        </div>
      </>
    );
  }
}

export default QueueMapPointer;
