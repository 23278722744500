import FirebaseAuthService from "firebase-auth-service";
import { sleepTime } from "helper-methods";
import { createUser, getClinicDetails, getSTaffUserInfo } from "http-calls";
import AccountClinicSelector from "modules/general/components/account-clinic-selector/account-clinic-selector";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import { clearUserData, updateUserData } from "redux/actions/user-data";
import { store } from "redux/store";
import { loadClinicApps } from '../redux/actions/clinic-settings';

const AccessGuard = (props) => {
  const [allowedClinics, setAllowedClinics] = useState([]);
  const [isChildVisible, setIsChildVisible] = useState(false);
  const [isClinicSelectorVisible, setIsClinicSelectorVisible] = useState(false);

  const _fetchStaffInfo = async () => {
    try {
      if (store.getState().userData.email) {
        const res = await getSTaffUserInfo(store.getState().userData.email);
        let clinics = [];
        let fetchCalls = [];
        if (res.length) {
          res.forEach((clinic) => {
            fetchCalls.push(getClinicDetails(clinic.ClinicID));
            // fetchCalls.push(getClinicDetails(clinic.ClinicID));
          });
          clinics = await Promise.all(fetchCalls);
          clinics = clinics.map((c) => c[0]);
        }
        return clinics;
      } else {
        props.hideBottomLoader();
        throw "error";
      }
    } catch (error) {
      props.hideBottomLoader();
      console.log("error :>> ", error);
      // _logout();
    }
  };

  const _filterClinics = async (allClinics) => {
    try {
      const { accessLevel } = props;
      const allowedAccess = await getSTaffUserInfo(
        store.getState().userData.email
      );
      const allowedClinicIds = [];
      allowedAccess.forEach((access) => {
        if (accessLevel.indexOf(access.UserAdminRole) > -1) {
          allowedClinicIds.push(access.ClinicID);
        }
      });
      let filteredClinics = allClinics.filter((clinic) => {
        if (allowedClinicIds.indexOf(clinic.ClinicID) > -1) {
          return true;
        }
      });
      const mergedClinics = {};
      filteredClinics.forEach((filteredClinic) => {
        if (!mergedClinics[filteredClinic.ClinicID]) {
          mergedClinics[filteredClinic.ClinicID] = filteredClinic;
        }
      });
      return Object.values(mergedClinics);
    } catch (error) {
      return false;
    }
  };

  const _initiateCheck = async () => {
    props.showLoader("Please wait");
    // Get staff info from azure auth
    store.dispatch(
      updateUserData({ token: FirebaseAuthService.getFirebaseClientToken() })
    );
    await sleepTime(500);
    await createUser();
    // Fetch staff info
    const allowedClinics = await _fetchStaffInfo();
    const allowedClinicsForCurrentRoute = await _filterClinics(allowedClinics);
    if (allowedClinicsForCurrentRoute.length) {
      setAllowedClinics(allowedClinicsForCurrentRoute);
    } else {
      // No access
      _logout();
    }
  };

  const _logout = () => {
    FirebaseAuthService.logout();
  };

  const _selectClinic = async (clinic) => {
    props.updateUserData({
      selectedClinic: clinic,
    });
    props.loadClinicApps(clinic.ClinicID);
    setIsClinicSelectorVisible(false);
    setIsChildVisible(true);
  };

  useEffect(() => {
    console.log('allowedClinics :>> ', allowedClinics);
    if (allowedClinics.length) {
      if (allowedClinics.length === 1) {
        // One clinic available
        // Select it as default
        props.hideLoader();
        props.updateUserData({
          selectedClinic: allowedClinics[0],
        });
        props.loadClinicApps(allowedClinics[0].ClinicID);
        setIsClinicSelectorVisible(false);
        setIsChildVisible(true);
      } else {
        // More than one clinic available
        if (props.userData.selectedClinic) {
          props.hideLoader();
          setIsChildVisible(true);
        } else {
          props.hideLoader();
          setIsClinicSelectorVisible(true);
        }
      }
    }
  }, [allowedClinics]);

  useEffect(() => {
    _initiateCheck();
  }, []);

  let currentlySelectedClinicName = "Loading";
  if (
    props.userData &&
    props.userData.selectedClinic &&
    props.userData.selectedClinic.Name
  ) {
    currentlySelectedClinicName = props.userData.selectedClinic.Name;
  }
  return (
    <>
      {isChildVisible && props.children}
      {!isClinicSelectorVisible && (
        <>
          {allowedClinics && allowedClinics.length > 0 ? (
            <div className="clinicSwitcherWrapper">
              <div className="clinicSwitcher">
                <i class="fa fa-building" aria-hidden="true"></i>

                <span className="clinicLabel">
                  {currentlySelectedClinicName}
                </span>
                {allowedClinics && allowedClinics.length > 1 ? (
                  <button
                    className="switcher"
                    onClick={(e) => {
                      setIsClinicSelectorVisible(true);
                      setIsChildVisible(false);
                    }}
                  >
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      <AccountClinicSelector
        clinics={allowedClinics}
        isVisible={isClinicSelectorVisible}
        onClinicSelect={_selectClinic}
        onLogout={_logout}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
    updateUserData: (userData) => dispatch(updateUserData(userData)),
    clearUserData: () => dispatch(clearUserData()),
    loadClinicApps: (clinicId) => dispatch(loadClinicApps(clinicId)),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessGuard);
