import AccessGuard from "guards/access-guard";
import React, { Component } from "react";
import LabAssistantView from "./pages/lab-assistant-view/lab-assistant-view";

class LabAssistantModule extends Component {
  state = {};
  render() {
    return (
      <>
          <AccessGuard accessLevel={["SuperAdmin", "ClinicAdmin", "Registration"]}>
            <LabAssistantView {...this.props} />
          </AccessGuard>
      </>
    );
  }
}

export default LabAssistantModule;
