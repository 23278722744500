import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ClinicManagementModule from "./clinic-management-module";
import ClinicSystemsSettingsModule from "./clinic-system-settings-module";
import DashboardModule from "./dashboard-module";
import ExpressCheckinModule from "./express-checkin-module";
import FaqModule from "./faq-module";
import FLowCustomizer from "./flow-customizer";
import ProtectedRoute from "./general/components/protected-route/protected-route";
import PublicRoute from "./general/components/public-route/public-route";
import LabAssistantModule from "./lab-assistant-module/index";
import MapboxNavigator from "./mapbox-navigator";
import PatientStatusModule from "./patient-status-module";
import PhlebotomistModule from "./phlebotomist-module/index";
import PublicModule from "./public-module";
import RegistrationModule from "./registration-module/index";
import ScreenBuilder from "./screen-builder";
import TvDisplayModule from "./tv-display";
import TvTableCustomizer from "./tv-table-customizer";
import UserActivation from "./user-activation/index";
import UserManagementModule from "./user-management-module";
import VisitSystemsSettingsModule from "./visit-system-settings-module";
import ManageAppsModule from './manage-apps/index';

class Modules extends Component {
  state = {};
  render() {
    return (
      <>
        <Switch>
          {/* Public routes */}
          <PublicRoute
            path="/public"
            component={PublicModule}
            redirectRoute="/registration"
          />
          {/* Private routes */}
          <Route path="/intakeform" component={RegistrationModule} />
          <Route path="/patient-status" component={PatientStatusModule} />
          <ProtectedRoute
            path="/registration"
            component={LabAssistantModule}
            redirectRoute="/public/auth?type=login"
          />
          <ProtectedRoute
            path="/orderactivation"
            component={UserActivation}
            redirectRoute="/public/auth?type=login"
          />
          <Route path="/phlebotomist" component={PhlebotomistModule} />
          <ProtectedRoute
            path="/manage-flows"
            component={FLowCustomizer}
            redirectRoute="/public/auth?type=login"
          />
          <ProtectedRoute
            path="/screen-builder"
            component={ScreenBuilder}
            redirectRoute="/public/auth?type=login"
          />
          <ProtectedRoute
            path="/manage-users"
            component={UserManagementModule}
            redirectRoute="/public/auth?type=login"
          />
          <ProtectedRoute
            path="/manage-tv-screen"
            component={TvTableCustomizer}
            redirectRoute="/public/auth?type=login"
          />
          <Route path="/express-checkin" component={ExpressCheckinModule} />
          <ProtectedRoute
            path="/dashboard"
            component={DashboardModule}
            redirectRoute="/public/auth?type=login"
          />
          <ProtectedRoute
            path="/manage-clinics"
            component={ClinicManagementModule}
            redirectRoute="/public/auth?type=login"
          />
          <Route path="/navigate" component={MapboxNavigator} />
          <ProtectedRoute
            path="/manage-clinic-system-settings"
            component={ClinicSystemsSettingsModule}
            redirectRoute="/public/auth?type=login"
          />
          {/* <ProtectedRoute
            path="/manage-visit-options"
            component={VisitSystemsSettingsModule}
            redirectRoute="/public/auth?type=login"
          /> */}
          <ProtectedRoute
            path="/manage-clinic-apps"
            component={ManageAppsModule}
            redirectRoute="/public/auth?type=login"
          />
          <ProtectedRoute
            path="/faqs"
            component={FaqModule}
            redirectRoute="/public/auth?type=login"
          />
          <Route path="/tv" component={TvDisplayModule} />
          <Route path="*" render={() => <Redirect to="/public" />} />
        </Switch>
      </>
    );
  }
}

export default Modules;
