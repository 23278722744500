import NavPills from "components/NavPills/NavPills.js";
import LogoutNotifier from "modules/general/components/logout-notifier/logout-notifier";
import NoAnswerView from "modules/lab-assistant-module/components/no-answer-view/no-answer-view";
import OrderActivationTable from "modules/user-activation/containers/order-activation-table/order-activation-table";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSTaffUserInfo,
  getVisitSystemSettingsOptions,
} from "../../../../http-calls/index";
import Header from "../../../general/containers/header/header";

const OrderActivationView = (props) => {
  const [activeView, setActiveView] = useState("orderActivationView");
  const [isSmsCommunicationEnabled, setIsSmsCommunicationEnabled] = useState(
    false
  );
  const [orderActivationCount, setOrderActivationCount] = useState(0);
  const [noAnswerCount, setNoAnswerCount] = useState(0);
  const [clinicId, setClinicId] = useState("-1");
  const [isLogoutNotifierVisible, setIsLogoutNotifierVisible] = useState(false);

  const _updateActiveView = (viewIndex) => {
    if (viewIndex === 0) {
      setActiveView("orderActivationView");
    } else if (viewIndex === 1) {
      setActiveView("noAnswer");
    }
  };

  const _setClinicIdFromRedux = () => {
    setClinicId(props.userData.selectedClinic.ClinicID);
  };

  const _getActiveIndex = () => {
    if (activeView === "orderActivationView") {
      return 0;
    } else if (activeView === "noAnswer") {
      return 1;
    }
  };

  const _logout = () => {
    setIsLogoutNotifierVisible(true);
  };

  const _checkIfUserHasAccess = async () => {
    try {
      if (
        props.userData &&
        props.userData.email &&
        props.userData.email.length
      ) {
        const res = await getSTaffUserInfo(props.userData.email);
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error :>> ", error);
      _logout();
    }
  };

  const _fetchVisitOptions = async () => {
    const visitSettings = await getVisitSystemSettingsOptions(clinicId);
    const isSmsCommunicationEnabled = visitSettings.find(
      (s) => s.PatientVisitOption === "SMS Communication"
    );
    setIsSmsCommunicationEnabled(!!isSmsCommunicationEnabled);
  };

  useEffect(() => {
    if (clinicId > -1) {
      _fetchVisitOptions();
    }
  }, [clinicId]);

  const _generateTabLabel = (tabButtonLabel, patientCount) => {
    return (
      <>
        <div className="tabButtonWrapper">
        {tabButtonLabel}
        {
          patientCount && parseInt(patientCount) > 0? (
            <div className="customBadge">{patientCount}</div>
          ): <></>
        }
        </div>
      </>
    );
  };

  useEffect(() => {
    _checkIfUserHasAccess();
    _setClinicIdFromRedux();
    // const clinicId = 1;
    // setClinicId(clinicId);
  }, []);

  return (
    <>
      <LogoutNotifier isVisible={isLogoutNotifierVisible} />

      <Header>
        {clinicId > -1 ? (
          <>
            <NavPills
              active={_getActiveIndex()}
              color="info"
              onChange={_updateActiveView}
              tabs={[
                {
                  tabButton: "Order Activations",
                  tabButton: (_generateTabLabel('Order Activations', orderActivationCount)),
                  tabContent: <></>,
                },
                {
                  tabButton: "No Answer",
                  tabButton: (_generateTabLabel('No Answer', noAnswerCount)),
                  tabContent: <></>,
                },
              ]}
            />
            <OrderActivationTable
              setActiveView={setActiveView}
              activeView={activeView}
              clinicId={clinicId}
            />
            <NoAnswerView
              activeView={activeView}
              clinicId={clinicId}
              setActiveView={setActiveView}
              logout={_logout}
            />
          </>
        ) : (
          <div className="clinicIdError">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p>
              This seems to be a configuration error. <br /> Please notify the
              front desk.
            </p>
          </div>
        )}
      </Header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, null)(OrderActivationView);
