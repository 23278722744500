import AccessGuard from "guards/access-guard";
import Header from "modules/general/containers/header/header";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./index.scss";
import ManageColumns from "./pages/manage-columns/manage-columns";
import ManageDevices from "./pages/manage-devices/manage-devices";
import ManageScreens from "./pages/manage-screens/manage-screens";
import { connect } from "react-redux";

class ScreenBuilder extends Component {
  state = {};
  _shouldShow = () => {
    if (this.props?.clinicSettings?.clinicApps) {
      const screenBuilderIndex = this.props.clinicSettings.clinicApps.findIndex(
        (a) => a.AppAddOnID === 4
      );
      if (screenBuilderIndex === -1) {
        return false;
      }
    }
    return true;
  };

  render() {
    let isVisible = this._shouldShow();
    return (
      <>
        <AccessGuard
          accessLevel={["SuperAdmin", "ClinicAdmin", "Registration"]}
        >
          <Header>
            {isVisible ? (
              <Switch>
                <Route
                  path="/screen-builder/manage-devices"
                  component={ManageDevices}
                />
                <Route
                  path="/screen-builder/manage-screens"
                  component={ManageScreens}
                />
                <Route
                  path="/screen-builder/manage-columns"
                  component={ManageColumns}
                />
                <Route
                  path="*"
                  render={() => (
                    <Redirect to="/screen-builder/manage-devices" />
                  )}
                />
              </Switch>
            ) : (
              <></>
            )}
          </Header>
        </AccessGuard>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    clinicSettings: state.clinicSettings,
  };
};

export default connect(mapStateToProps, null)(ScreenBuilder);
