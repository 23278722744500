import React, { useState, useEffect } from "react";
import UserSelectionView from "./components/user-selection-view/user-selection-view";
import Header from "./containers/header/header";
import PatientTable from "./components/patient-table/patient-table";
import { extractQueryParams } from "helper-methods";
import { getAvailableStatesForCurrentState, getAllWorkflows } from "http-calls";
import { getClinicAppSettings } from "http-calls";

const PhlebotomistModule = (props) => {
  const [currentView, setCurrentView] = useState("users");
  const [selectedUser, setSelectedUser] = useState(null);
  const [clinicApps, setClinicApps] = useState([]);
  const [clinicId, setClinicId] = useState("-1");
  const [availableStates, setAvailableStates] = useState({
    phlebotomist: [],
    called: []
  });


  const _extractClinicId = () => {
    const params = extractQueryParams();
    const clinicId = Object.keys(params).find(param => param.toLocaleLowerCase() === 'clinicid');
    if (clinicId) {
      setClinicId(params[clinicId]);
    }
  };

  const _loadStateMetadata = async () => {
    try {
      const availableStates = {};
      const workFlows = await getAllWorkflows(clinicId);
      if (workFlows && workFlows.length) {
        const activeFlow = workFlows[0];
        availableStates.phlebotomist = await getAvailableStatesForCurrentState(
          4,
          activeFlow.WorkFlowID
        );
        availableStates.called = await getAvailableStatesForCurrentState(
          5,
          activeFlow.WorkFlowID
        );
        setAvailableStates(availableStates);
      } else {
        throw new Error();
      }
    } catch (error) {}
  };

  const _loadClinicApps = async () => {
    try {
      const clinicApps = await getClinicAppSettings(clinicId);
      setClinicApps(clinicApps);
    } catch (error) {}
  };


  const _logout = () => {
    setCurrentView("users");
    setSelectedUser(null);
  };

  useEffect(() => {
    _extractClinicId();
  }, []);

  useEffect(() => {
    if (clinicId>-1) {
      _loadStateMetadata();
      _loadClinicApps();
    }
  }, [clinicId])

  useEffect(() => {
    if (selectedUser) {
      setCurrentView("table");
    }
  }, [selectedUser]);

  return (
    <>
      <Header logout={_logout} selectedUser={selectedUser} />
      {clinicId > -1 ? (
        <>
          {currentView === "users" ? (
            <UserSelectionView
              selectUser={setSelectedUser}
              clinicId={clinicId}
            />
          ) : (
            <PatientTable selectedUser={selectedUser} clinicId={clinicId} logout={_logout} availableStates={availableStates} clinicApps={clinicApps} />
          )}
        </>
      ) : (
        <div className="clinicIdError" style={{width: "100vw", height: "100vh"}}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p>
            This seems to be a configuration error. <br /> Please notify the
            front desk.
          </p>
        </div>
      )}
    </>
  );
};

export default PhlebotomistModule;
