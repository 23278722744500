import { store } from "../redux/store";
import FirebaseAuthService from "firebase-auth-service";

/**
 *
 * Checks for auth token in auth state & storage
 *
 */
export const getToken = () => {
  return new Promise(async (resolve, reject) => {
    let token = null;
    const oldState = store.getState();
    const state = { ...oldState };
    // Try to get token from state
    // if (
    //   state &&
    //   state.userData &&
    //   state.userData.email
    // ) {
    // }
    try {
      token = await FirebaseAuthService.getFirebaseClientToken();
    } catch (error) {
    }
    resolve(token);
  });
};
