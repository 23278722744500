import { deepClone, showToast, sleepTime } from "helper-methods";
import {
  getAvailableStatesForCurrentState,
  getMyPhlebotomistPatients,
  getPhlebotomistPatientsInQueue,
  getVisitSystemSettingsOptions,
  sendMessage,
  sendSurveyRequest,
  updatePatientStatus,
} from "http-calls";
import ExtendedActions from "modules/general/components/extended-actions/extended-actions";
import SearchInput from "modules/general/components/search-input/search-input";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import "./patient-table.scss";

let intervalRef = null;

const PatientTable = (props) => {
  const [patientsInQueue, setPatientsInQueue] = useState([]);
  const [myPatients, setMyPatients] = useState([]);
  const [availableStates, setAvailableStates] = useState([]);
  const [filteredRowsForMyPatients, setFilteredRowsForMyPatients] = useState(
    []
  );
  const [
    filteredRowsForOtherPatients,
    setFilteredRowsForOtherPatients,
  ] = useState([]);
  const [
    searchValueForOtherPatients,
    setSearchValueForOtherPatients,
  ] = useState("");
  const [searchValueForMyPatients, setSearchValueForMyPatients] = useState("");
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const _loadPatients = async () => {
    _initiateBackgroundRefresher();
    try {
      const myPatientsResponse = await getMyPhlebotomistPatients(
        props.selectedUser.Username,
        props.clinicId
      );
      setMyPatients(myPatientsResponse);
    } catch (error) {
      console.log("error :>> ", error);
      setMyPatients([]);
      // showToast("Error while assigning");
      props.hideLoader();
    }
    try {
      const patientsInQueueResponse = await getPhlebotomistPatientsInQueue(
        props.clinicId
      );
      setPatientsInQueue(patientsInQueueResponse);
      props.hideLoader();
    } catch (error) {
      console.log("error :>> ", error);
      // showToast("Error while assigning");
      setPatientsInQueue([]);
      await sleepTime(700);
      props.hideLoader();
    }
  };

  const _filterRowsForMyPatients = () => {
    let filteredRows = deepClone(myPatients);
    const lowerCasedSearchValue = searchValueForMyPatients.toLowerCase().trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredRows = filteredRows.filter((row) => {
        if (row.CallNumber.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if (
          `${row.FirstName} ${row.LastName}`
            .toLowerCase()
            .indexOf(lowerCasedSearchValue) > -1
        ) {
          return true;
        }
      });
    }
    setFilteredRowsForMyPatients(filteredRows);
  };

  const _filterRowsForOtherPatients = () => {
    let filteredRows = deepClone(patientsInQueue);
    const lowerCasedSearchValue = searchValueForOtherPatients
      .toLowerCase()
      .trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredRows = filteredRows.filter((row) => {
        if (row.CallNumber.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if (
          `${row.FirstName} ${row.LastName}`
            .toLowerCase()
            .indexOf(lowerCasedSearchValue) > -1
        ) {
          return true;
        }
      });
    }
    setFilteredRowsForOtherPatients(filteredRows);
  };

  const _sendSurveyLinkToPatient = async (patient) => {
    const { clinicApps = [] } = props;
    const isSurveyEnabled = clinicApps.find(app => app.AppAddOn === 'Post Visit Survey');
    if (isSurveyEnabled) {
      try {
        const surveyPayload = {
          FlowID: "FW9546dbc4230168cbb18a3692004a11af",
          ToPhoneNumber: patient.MobileNumber,
          FlowPhoneNumber: "3232741944",
        };
        await sendSurveyRequest(surveyPayload);
      } catch (error) {
        console.log("error :>> ", error);
      }
    }
  };

  // const _addToMyself = async (patient) => {
  //   try {
  //     props.showBottomLoader("Calling");
  //     await updatePatientStatus({
  //       ...patient,
  //       PatientStatusTypeID: 5,
  //       UpdatedBy: props.selectedUser.Username,
  //     });
  //     _sendSmsToPatient(patient);
  //     props.logout();
  //   } catch (error) {
  //     props.hideLoader();
  //   }
  // };

  const _sendSmsToPatient = async (patient) => {
    const { clinicApps = [] } = props;
    
    const isSmsEnabled = clinicApps.find(app => app.AppAddOn === 'SMS Communication');
    if (isSmsEnabled) {
      const payload = {
        Message: `Please come to lab. ${props.selectedUser.FirstName} ${props.selectedUser.LastName} is ready for you`,
        ToPhoneNumber: patient.MobileNumber,
        PatientVisitID: patient.PatientVisitID,
        Sender: props.selectedUser.Username,
      };
      try {
        await sendMessage(payload);
        showToast("SMS sent to patient", "success", {
          position: "bottom-center",
        });
      } catch (error) {}
    }
  };

  // const _markPatientAsComplete = async (patient) => {
  //   try {
  //     props.showBottomLoader("Marking as complete");
  //     await updatePatientStatus({
  //       ...patient,
  //       PatientStatusTypeID: 6,
  //       UpdatedBy: props.selectedUser.Username,
  //     });
  //     await _sendSurveyLinkToPatient(patient);
  //     props.hideLoader();
  //     await sleepTime(500);
  //     props.logout();
  //   } catch (error) {
  //     props.hideLoader();
  //   }
  // };

  // const _markPatientAsNoAnswer = async (patient) => {
  //   try {
  //     props.showBottomLoader("Calling");
  //     await updatePatientStatus({
  //       ...patient,
  //       PatientStatusTypeID: 8,
  //       UpdatedBy: props.selectedUser.Username,
  //     });
  //     props.hideLoader();
  //     await sleepTime(500);
  //     props.logout();
  //   } catch (error) {
  //     props.hideLoader();
  //   }
  // };

  const _initiateBackgroundRefresher = () => {
    if (!intervalRef) {
      intervalRef = setInterval(() => {
        _loadPatients();
      }, 5000);
    }
  };

  const _showActionModal = (patient) => {
    console.log('patient :>> ', patient);
    setSelectedPatient(patient);
    setIsActionModalVisible(true);
  };

  const _loadStateMetadata = async () => {
    try {
      const availableStates = await getAvailableStatesForCurrentState(3);
      setAvailableStates(availableStates);
    } catch (error) {}
  };

  useEffect(() => {
    _loadStateMetadata();
  }, []);

  useEffect(() => {
    props.showLoader("Loading");
    _loadPatients();
    return () => {
      clearInterval(intervalRef);
    };
  }, [availableStates]);

  useEffect(() => {
    _filterRowsForMyPatients();
  }, [myPatients]);

  useEffect(() => {
    _filterRowsForMyPatients();
  }, [searchValueForOtherPatients]);

  useEffect(() => {
    _filterRowsForOtherPatients();
  }, [patientsInQueue]);

  useEffect(() => {
    _filterRowsForOtherPatients();
  }, [searchValueForOtherPatients]);

  const _updatePatientStatus = async (patient, status) => {
    console.log('patient :>> ', patient);
    // Call API to update the status
    props.showBottomLoader("Updating");
    let PatientStatusTypeID = status;
    await updatePatientStatus({
      ...patient,
      UpdatedBy: props.selectedUser.Username,
      PatientStatusTypeID,
    });
    if (PatientStatusTypeID == 5) {
      // Called
      await _sendSmsToPatient(patient);
    }
    if (PatientStatusTypeID == 6) {
      // Completed
      await _sendSurveyLinkToPatient(patient);
    }
    props.logout();
    props.hideLoader();
  };

  const myPatientActions = props.availableStates.called.map((state) => ({
    label: state.Status,
    onClick: () => {
      setIsActionModalVisible(false);
      _updatePatientStatus(selectedPatient, state.StatusID);
    },
    onInlineButtonClick: (patient) => {
      _updatePatientStatus(patient, state.StatusID);
    },
  }));

  const allPatientActions = props.availableStates.phlebotomist.map((state) => ({
    label: state.Status,
    onClick: () => {
      setIsActionModalVisible(false);
      _updatePatientStatus(selectedPatient, state.StatusID);
    },
    onInlineButtonClick: (patient) => {
      _updatePatientStatus(patient, state.StatusID);
    },
  }));

  if (myPatients.length) {
    return (
      <div className="tableWrapper">
        <ExtendedActions
          isVisible={isActionModalVisible}
          onCancel={(e) => setIsActionModalVisible(false)}
          actions={myPatientActions}
          modalHeight={200 + 80 * myPatientActions.length + "px"}
          selectedPatient={selectedPatient}
        />
        <h3>My Patients</h3>

        <div id="table" className="pTable">
          <div className="tableActions centered">
            <SearchInput
              value={searchValueForMyPatients}
              onChange={setSearchValueForMyPatients}
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>Call Number</th>
                <th>Name</th>
                <th>Reason for Visit</th>
                <th>Comment</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredRowsForMyPatients.map((patient, patientIndex) => (
                <tr key={patientIndex}>
                  <td>{patient.CallNumber}</td>
                  <td>{patient.FirstName + " " + patient.LastName}</td>
                  <td>{patient.VisitType.toUpperCase()}</td>
                  <td>{patient.internalcomments}</td>
                  <td>
                    <div className="actions">
                      <div className="buttonsWrapper">
                        {myPatientActions.length > 2 ? (
                          <button
                            className="actions"
                            onClick={(e) => _showActionModal(patient)}
                          >
                            <i className="fa fa-cog" aria-hidden="true"></i>
                          </button>
                        ) : (
                          <>
                            {myPatientActions.map((action, actionIndex) => (
                              <button
                                key={actionIndex}
                                onClick={(e) => action.onInlineButtonClick(patient)}
                                className="completeBtn"
                              >
                                {action.label}
                              </button>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {filteredRowsForMyPatients.length === 0 && (
            <div id="noCell">No Patients Available</div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="tableWrapper">
        <ExtendedActions
          isVisible={isActionModalVisible}
          onCancel={(e) => setIsActionModalVisible(false)}
          actions={allPatientActions}
          modalHeight={200 + 80 * allPatientActions.length + "px"}
          selectedPatient={selectedPatient}
        />
        <h3>Available Patients</h3>
        <div id="table" className="pTable">
          <div className="tableActions centered">
            <SearchInput
              value={searchValueForOtherPatients}
              onChange={setSearchValueForOtherPatients}
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>Call Number</th>
                <th>Name</th>
                <th>Reason for Visit</th>
                <th>Comment</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredRowsForOtherPatients.map((patient, patientIndex) => (
                <tr key={patientIndex}>
                  <td>{patient.CallNumber}</td>
                  <td>{patient.FirstName + " " + patient.LastName}</td>
                  <td>{patient.VisitType.toUpperCase()}</td>
                  <td>{patient.internalcomments}</td>
                  <td>
                    <div className="actions">
                      <div className="buttonsWrapper">
                        {allPatientActions.length > 2 ? (
                          <button
                            className="actions"
                            onClick={(e) => _showActionModal(patient)}
                          >
                            <i className="fa fa-cog" aria-hidden="true"></i>
                          </button>
                        ) : (
                          <>
                            {allPatientActions.map((action, actionIndex) => (
                              <button
                                key={actionIndex}
                                onClick={(e) => action.onInlineButtonClick(patient)}
                                className="completeBtn"
                              >
                                {action.label}
                              </button>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {filteredRowsForOtherPatients.length === 0 && (
            <div id="noCell">No Patients Available</div>
          )}
        </div>
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

export default connect(null, mapDispatchToProps)(PatientTable);
