import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AccessTime from "@material-ui/icons/AccessTime";
import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import MatHeader from "../../../../components/Header/Header.js";
import "./header.scss";
import LanguagePrompt from "modules/registration-module/components/language-prompt/language-prompt.jsx";
import MultiLang from "multi-lang/index.js";

let clockRef = null;

const useStyles = makeStyles(navbarsStyle);

const lanuageOptions = [
  { label: "English", languageKey: "english" },
  { label: "Español", languageKey: "spanish" },
  { label: "中文", languageKey: "chinese" },
  { label: "Հայերեն", languageKey: "armenian" },
  { label: "Русский", languageKey: "russian" },
  { label: "العربية", languageKey: "arabic" },
  { label: "Tiếng Việt", languageKey: "vietnamese" },
  { label: "한국어", languageKey: "korean" },
  { label: "Tagalog", languageKey: "tagalog" },
];

const Header = (props) => {
  const [currentTime, setCurrentTime] = useState(moment().format("LT"));
  const [isLanguagePromptVisible, setIsLanguagePromptVisible] = useState(false);
  const classes = useStyles();
  const _navigateToWizard = () => {
    if (props.restart) {
      props.restart();
    } else {
      props.history.push("/intakeform");
    }
  };

  useEffect(() => {
    clockRef = setInterval(() => {
      setCurrentTime(moment().format("LT"));
    }, 1000);
    return () => {
      clearInterval(clockRef);
    };
  }, []);

  const _getLanguageLabel = (languageCode) => {
    return lanuageOptions.find((l) => l.languageKey === languageCode)["label"];
  };

  return (
    <div id="wizradHeader">
      <MatHeader
        brand="Express Checkin"
        color="#990000"
        links={
          <List className={classes.list + " " + classes.mlAuto}>
            {!props.hideLanguagePrompt && (
              <ListItem className={classes.listItem}>
                <div
                  className="languagePicker"
                  onClick={(e) => setIsLanguagePromptVisible(true)}
                >
                  <span>{_getLanguageLabel(MultiLang.currentLangCode)}</span>
                  <div className="iconWrapper">
                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                  </div>
                </div>
              </ListItem>
            )}
            <ListItem className={classes.listItem}>
              <div className="clockWrapper">
                <AccessTime /> {currentTime}
              </div>
            </ListItem>
          </List>
        }
      />
      <LanguagePrompt
        isVisible={isLanguagePromptVisible}
        onHide={(e) => setIsLanguagePromptVisible(false)}
      />
    </div>
  );
};

export default Header;
