import AccessGuard from "guards/access-guard";
import React from "react";
import OrderActivationView from "./pages/order-activation-view/order-activation-view";
import "./style.scss";

const UserActivation = (props) => {
  return (
    <>
      <AccessGuard accessLevel={["SuperAdmin", "ClinicAdmin", "OrderActivation"]}>
        <OrderActivationView />
      </AccessGuard>
    </>
  );
};

export default UserActivation;
