import { makeStyles } from "@material-ui/core/styles";
import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js";
import FirebaseAuthService from "firebase-auth-service";
import { extractQueryParams } from "helper-methods/index.js";
import { getSTaffUserInfo } from "http-calls";
import { initiateDashboard } from "library-scripts/dashboard.js";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { clearUserData } from "redux/actions/user-data";
import { store } from "redux/store";
import { EventEmitter } from "utils/event-emitter";
import Prompt from "../../../general/components/prompt/prompt";
import "./header.scss";
import { showLoader } from "redux/actions/loader-data";
import { hideLoader } from "redux/actions/loader-data";
import { showBottomLoader } from "redux/actions/loader-data";
import { hideBottomLoader } from "redux/actions/loader-data";
import { getCurrentClinicDetails } from "http-calls";
import { capitalizeFirstLetter } from "../../../../helper-methods/index";
import ClinicDataParser from "../../../../utils/clinic-data-parser";
import LogoutNotifier from '../../components/logout-notifier/logout-notifier';

const useStyles = makeStyles(navbarsStyle);

const sideLinks = [
  {
    path: "/dashboard",
    label: "Dashboard",
    passClinicId: false,
    icon: <i class="fa fa-bar-chart" aria-hidden="true"></i>,
    hasAccess: (userInfo) => {
      return true;
    },
  },
  {
    path: "/registration",
    label: "Registrations",
    passClinicId: false,
    icon: <i className="material-icons">list</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin" ||
          userInfo.UserAdminRole === "Registration")
      ) {
        return true;
      }
    },
  },
  {
    path: "/orderactivation",
    label: "Order Activations",
    passClinicId: false,
    icon: <i className="material-icons">how_to_reg</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin" ||
          userInfo.UserAdminRole === "OrderActivation")
      ) {
        return true;
      }
    },
  },
  {
    path: "/manage-clinic-apps",
    label: "Manage Apps",
    passClinicId: false,
    icon: <i className="material-icons">library_add_check</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin")
      ) {
        return true;
      }
    },
  },
  {
    path: "/manage-users",
    label: "Manage Users",
    passClinicId: false,
    isManageSection: true,
    icon: <i className="material-icons">group</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin")
      ) {
        return true;
      }
    },
  },
  {
    path: "/manage-clinics",
    label: "Manage Clinics",
    passClinicId: false,
    isManageSection: true,
    icon: <i className="material-icons">store</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        userInfo.UserAdminRole === "SuperAdmin"
      ) {
        return true;
      }
    },
  },
  {
    path: "/screen-builder",
    label: "Manage Screens",
    passClinicId: false,
    isManageSection: true,
    icon: <i className="material-icons">video_settings</i>,
    hasAccess: (userInfo, clinicApps) => {
      if (clinicApps?.length) {
        const screenBuilderIndex = clinicApps.findIndex(
          (a) => a.AppAddOnID === 4
        );
        if (screenBuilderIndex > -1) {
          if (
            userInfo &&
            userInfo.UserAdminRole &&
            (userInfo.UserAdminRole === "SuperAdmin" ||
              userInfo.UserAdminRole === "ClinicAdmin")
          ) {
            return true;
          }
        }
      }
    },
  },
  {
    path: "/manage-flows",
    label: "Manage flows",
    passClinicId: false,
    isManageSection: true,
    icon: <i className="material-icons">account_tree</i>,
    hasAccess: (userInfo, clinicApps) => {
      if (clinicApps?.length) {
        const screenBuilderIndex = clinicApps.findIndex(
          (a) => a.AppAddOnID === 5
        );
        if (screenBuilderIndex > -1) {
          if (
            userInfo &&
            userInfo.UserAdminRole &&
            (userInfo.UserAdminRole === "SuperAdmin" ||
              userInfo.UserAdminRole === "ClinicAdmin")
          ) {
            return true;
          }
        }
      }
    },
  },
  {
    path: "/manage-clinic-system-settings",
    label: "Manage System Settings",
    passClinicId: false,
    isManageSection: true,
    icon: <i className="material-icons">brightness_high</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin")
      ) {
        return true;
      }
    },
  },
  {
    path: "/faqs",
    label: "Documentation",
    passClinicId: false,
    additionalClass: "bottomElements",
    icon: <i className="fa fa-question-circle-o" aria-hidden="true"></i>,
    hasAccess: (userInfo) => {
      return true;
    },
  },
];

const Header = (props) => {
  const { userData } = props;
  const [userInfo, setUserInfo] = useState(null);
  const [isLogoutPromptVisible, setISLogoutPromptVisible] = useState(false);
  const classes = useStyles();
  const [params, setParams] = useState("");
  const [userAccess, setUserAccess] = useState(null);
  const [clinicDetails, setClinicDetails] = useState(null);
  const sidebarToggleBtnRef = useRef(null);
  const [isLogoutNotifierVisible, setIsLogoutNotifierVisible] = useState(false);

  const _logout = () => {
    setIsLogoutNotifierVisible(true);
    setISLogoutPromptVisible(false);
    store.dispatch(clearUserData());
    FirebaseAuthService.logout();
    localStorage.clear();
    window.location = window.origin;
  };

  const _setParams = () => {
    const params = extractQueryParams();
    const clinicId = Object.keys(params).find(
      (param) => param.toLocaleLowerCase() === "clinicid"
    );
    if (clinicId) {
      setParams(`?clinicId=${params[clinicId]}`);
    }
  };

  const _getUserInfo = async () => {
    // console.log('userInfo :>> ', userInfo);
    try {
      if (
        props.userData &&
        props.userData.email &&
        props.userData.email.length
      ) {
        props.showBottomLoader("Loading sidelinks");
        const res = await getSTaffUserInfo(props.userData.email);
        setUserAccess(res[0]);
        props.hideBottomLoader();
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error :>> ", error);
      props.hideLoader();
    }
  };

  const _showQuickSearch = () => {
    EventEmitter.dispatch("onQuickSearch");
  };

  useEffect(() => {
    setUserInfo(props.userData);
    setTimeout(() => {
      initiateDashboard();
    }, 1000);
    setTimeout(() => {
      if (sidebarToggleBtnRef.current) {
        sidebarToggleBtnRef.current.click();
      }
    }, 1100);
    _getUserInfo();
    _setParams();
  }, []);

  useEffect(() => {
    if (props?.userData?.selectedClinic?.ClinicID) {
      _fetchClinicDetails();
    }
  }, [props.userData.selectedClinic]);

  const _fetchClinicDetails = async () => {
    try {
      const clinicDetails = await getCurrentClinicDetails(
        props.userData.selectedClinic.ClinicID
      );
      if (clinicDetails && clinicDetails.length && clinicDetails[0]) {
        setClinicDetails(clinicDetails[0]);
      }
    } catch (error) {}
  };

  const _isLinkActive = (link) => {
    if (window.location.pathname.indexOf(link) > -1) {
      return true;
    } else {
      return false;
    }
  };

  const _showActiveLinkLabel = () => {
    const activeLink = sideLinks.find(
      (link) => window.location.pathname.indexOf(link.path) > -1
    );
    return activeLink.label;
  };

  const _getThemeColor = () => {
    let defaultColor = "#990000";
    if (userData?.selectedClinic?.themeColor?.length) {
      defaultColor = userData?.selectedClinic?.themeColor;
    }
    return defaultColor;
  };

  const themeColor = _getThemeColor();
  const logoUrl = userData?.selectedClinic?.Logo?.length
    ? userData?.selectedClinic?.Logo
    : null;

  const userLabel = ClinicDataParser.getUserLabel();

  return (
    <div>
      <div className="wrapper ">
        {userData?.selectedClinic ? (
          <div
            className="sidebar"
            data-color="white"
            data-background-color="black"
            data-image="../assets/img/sidebar-1.jpg"
          >
            <div className="logo" style={{ backgroundColor: themeColor }}>
              {/* <a href="#" className="simple-text logo-mini"></a> */}
              {logoUrl ? (
                <img src={logoUrl} alt="" />
              ) : (
                <a
                  href="#"
                  className="simple-text logo-normal"
                  id="telehealthLogo"
                >
                  Checkin
                </a>
              )}
            </div>
            <div
              className="sidebar-wrapper"
              style={{ backgroundColor: themeColor }}
            >
              <ul className="nav">
                {sideLinks.map((link, linkIndex) => (
                  <>
                    {link.hasAccess(
                      userAccess,
                      props.clinicSettings?.clinicApps
                    ) && !link.isManageSection ? (
                      <li
                        key={linkIndex}
                        className={
                          "nav-item " +
                          (link.additionalClass ? link.additionalClass : "") +
                          (_isLinkActive(link.path) ? " active " : "")
                        }
                      >
                        <Link
                          className="nav-link"
                          to={
                            link.path +
                            (link.passClinicId
                              ? props.userData &&
                                props.userData.selectedClinic &&
                                props.userData.selectedClinic.ClinicID
                                ? `?clinicId=${props.userData.selectedClinic.ClinicID}`
                                : ""
                              : "")
                          }
                        >
                          {link.icon}
                          <p> {link.label} </p>
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="main-panel">
          {/* Navbar */}
          <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
            <div className="container-fluid">
              <div className="navbar-wrapper">
                <div className="navbar-minimize">
                  <button
                    id="minimizeSidebar"
                    className="btn btn-just-icon btn-white btn-fab btn-round"
                    ref={sidebarToggleBtnRef}
                  >
                    <i className="material-icons text_align-center visible-on-sidebar-regular">
                      more_vert
                    </i>
                    <i className="material-icons design_bullet-list-67 visible-on-sidebar-mini">
                      view_list
                    </i>
                  </button>
                </div>
                <a className="navbar-brand" href="javascript:;">
                  {_showActiveLinkLabel()}
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="navbar-toggler-icon icon-bar" />
                <span className="navbar-toggler-icon icon-bar" />
                <span className="navbar-toggler-icon icon-bar" />
              </button>
              <div className="collapse navbar-collapse justify-content-end">
                <button
                  className="quickPatientSearchBtn"
                  onClick={_showQuickSearch}
                >
                  <i className="fa fa-users" aria-hidden="true" />
                  &nbsp; {capitalizeFirstLetter(userLabel)} Quick Search
                </button>
                <ul className="navbar-nav">
                  {userInfo && userInfo.email ? (
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link headerUser"
                        href="javascript:;"
                        id="navbarDropdownProfile"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">person</i>
                        {userInfo.email.split("@")[0]}
                        <p className="d-lg-none d-md-block">Account</p>
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="navbarDropdownProfile"
                      >
                        {sideLinks.map((link, linkIndex) => (
                          <>
                            {link.hasAccess(
                              userAccess,
                              props.clinicSettings.clinicApps
                            ) && link.isManageSection ? (
                              <a
                                key={linkIndex}
                                className="dropdown-item"
                                href="#"
                                onClick={(e) => e.preventDefault()}
                              >
                                <Link
                                  className="nav-link"
                                  to={
                                    link.path +
                                    (link.passClinicId
                                      ? props.userData &&
                                        props.userData.selectedClinic &&
                                        props.userData.selectedClinic.ClinicID
                                        ? `?clinicId=${props.userData.selectedClinic.ClinicID}`
                                        : ""
                                      : "")
                                  }
                                >
                                  <div className="manage-section">
                                    {link.icon}
                                    <span> {link.label} </span>
                                  </div>
                                </Link>
                              </a>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                        <div className="dropdown-divider" />
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setISLogoutPromptVisible(true);
                          }}
                        >
                          Log out
                        </a>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </nav>
          {/* End Navbar */}
          <div className="content">{props.children}</div>
          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="#">Checkin</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right"></div>
            </div>
          </footer>
        </div>
      </div>
      <Prompt
        isVisible={isLogoutPromptVisible}
        onCancel={() => setISLogoutPromptVisible(false)}
        onConfirm={_logout}
        bodyText={"Are you sure to logout?"}
        confirmText={"Logout"}
      />
      <LogoutNotifier isVisible={isLogoutNotifierVisible} reasonText={'Please wait'} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    clinicSettings: state.clinicSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
