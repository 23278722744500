import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HomePage from "./pages/home-page/home-page";
import AboutUsPage from "./pages/about-us-page/about-us-page";
import ContactUsPage from "./pages/contact-us-page/contact-us-page";
import "./assets/style.scss";
import ServicesPage from "./pages/services/services";
import AuthPage from "./pages/auth-page/auth-page";
import PublicRoute from "modules/general/components/public-route/public-route";

class PublicModule extends Component {
  state = {};
  render() {
    return (
      <div id="publicModule">
        <Switch>
          <Route path="/public/home" component={HomePage} />
          <Route path="/public/about-us" component={AboutUsPage} />
          <Route path="/public/services" component={ServicesPage} />
          <Route path="/public/contact-us" component={ContactUsPage} />
          <Route path="/public/contact-us" component={ContactUsPage} />
          <PublicRoute
            path="/public/auth"
            component={AuthPage}
            redirectRoute="/dashboard"
          />
          <Route path="*" render={() => <Redirect to="/public/home" />} />
        </Switch>
      </div>
    );
  }
}

export default PublicModule;
