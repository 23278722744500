// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import pillsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/pillsStyle.js";
import Accordion from "components/Accordion/Accordion.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import { faqData } from "config/faq-data";
import React from "react";
import "./faq-page.scss";
import ClinicDataParser from '../../../../utils/clinic-data-parser';
const useStyles = makeStyles(pillsStyle);

const FaqPage = () => {
  const classes = useStyles();

  const userLabel = ClinicDataParser.getUserLabel();

  const _format = () => {
    return faqData.map(tab => {
      return (
        {
          tabButton: _replaceWords(tab.tabButton),
          tabIcon: tab.tabIcon,
          tabData: JSON.parse(_replaceWords(JSON.stringify(tab.tabData)))
        }
      )
    })
  }

  const _replaceWords = (str) => {
    let formattedText = str;
    formattedText = formattedText.replace(/PATIENT/g, userLabel.toUpperCase());
    formattedText = formattedText.replace(/patient/g, userLabel.toLowerCase());
    formattedText = formattedText.replace(/Patient/g, userLabel);
    return formattedText;
  }

  const formattedFaq = _format();
  console.log('formattedFaq :>> ', formattedFaq);

  return (
    <>
      <div className="faqWrapper">
        <NavPills
          color="primary"
          tabs={formattedFaq.map((tab) => ({
            ...tab,
            tabContent: (
              <div className="accordianWrapper">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Accordion
                      active={0}
                      activeColor="#990000"
                      collapses={tab.tabData}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            ),
          }))}
        />
      </div>
    </>
  );
};

export default FaqPage;
