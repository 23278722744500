import { extractQueryParams } from "helper-methods";
import {
  addClinic,
  getClinicDetails,
  getSTaffUserInfo,
  updateClinic,
} from "http-calls";
import ClinicEditor from "modules/clinic-management-module/components/clinic-editor/clinic-editor";
import LogoutNotifier from "modules/general/components/logout-notifier/logout-notifier";
// import LogoutNotifier from "modules/general/components/logout-notifier/logout-notifier";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import Header from "../../../general/containers/header/header";
import "../../style.scss";
import "./clinic-management-table.scss";
import { getClinicList } from "http-calls";
import SearchInput from "modules/general/components/search-input/search-input";
import { deepClone } from "helper-methods";

const ClinicManagementTable = (props) => {
  const [clinics, setClinics] = useState([]);
  const [clinicId, setClinicId] = useState("-1");
  const [userInfo, setUserInfo] = useState(null);
  const [isClinicModalVisible, setIsClinicModalVisible] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [isLogoutNotifierVisible, setIsLogoutNotifierVisible] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const _updatePatientStatus = async (id, status) => {};

  const _setClinicIdFromRedux = () => {
    setClinicId(props.userData.selectedClinic.ClinicID);
  };

  const _loadUserData = async () => {
    setUserInfo(props.userData.email);
  };

  const _logout = () => {
    setIsLogoutNotifierVisible(true);
  };

  const _fetchClinics = async () => {
    try {
      const clinics = await getClinicList();
      setClinics(clinics);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const _checkIfUserHasAccess = async () => {
    try {
      if (props.userData && props.userData.email && props.userData.email.length) {
        const res = await getSTaffUserInfo(props.userData.email);
      } else {
        props.hideBottomLoader();
        throw "error";
      }
    } catch (error) {
      props.hideBottomLoader();
      console.log("error :>> ", error);
      _logout();
    }
  };

  const _initiateLoad = async () => {
    props.showLoader("Loading");
    await _checkIfUserHasAccess();
    await _fetchClinics();
    props.hideLoader();
  };

  useEffect(() => {
    _setClinicIdFromRedux();
    _initiateLoad();
  }, []);

  useEffect(() => {
    if (clinicId !== "-1") {
      _loadUserData();
    }
  }, [clinicId]);

  useEffect(() => {
    if (props.userData) {
    }
  }, [props.userData]);

  const _showClinicEditPrompt = (clinic) => {
    setSelectedClinic(clinic);
    setIsClinicModalVisible(true);
  };

  const _showClinicAddPrompt = () => {
    setIsClinicModalVisible(true);
  };

  const _hideClinicEditPrompt = () => {
    setSelectedClinic(null);
    setIsClinicModalVisible(false);
  };

  const _filterRows = () => {
    let filteredRows = deepClone(clinics);
    const lowerCasedSearchValue = searchValue.toLowerCase().trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredRows = filteredRows.filter((row) => {
        if (row.Name.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if (row.Location.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
      });
    }
    setFilteredRows(filteredRows);
  };

  const _updateClinic = async (updatedClinic) => {
    _hideClinicEditPrompt();
    props.showBottomLoader("Updating clinic");
    await updateClinic(updatedClinic);
    const { userData: {selectedClinic} } = props;
    if (selectedClinic.ClinicID === updatedClinic.ClinicID) {
      window.location.reload();
    } else {
      await _fetchClinics();
      props.hideLoader();
    }
  };

  const _addClinic = async (newClinic) => {
    _hideClinicEditPrompt();
    props.showBottomLoader("Adding new clinic");
    await addClinic(newClinic);
    await _fetchClinics();
    props.hideLoader();
  };

  const _manageUsers = (clinic) => {
    props.history.push(`/manage-users?clinicId=${clinic.ClinicID}`);
  };

  useEffect(() => {
    _filterRows();
  }, [clinics]);

  useEffect(() => {
    _filterRows();
  }, [searchValue]);

  return (
    <>
      <LogoutNotifier isVisible={isLogoutNotifierVisible} />
      <Header>
        <div id="labAssistantView" className="userActivationWrapper">
          <div className="clinicTableAction spaceBetween">
            <SearchInput value={searchValue} onChange={setSearchValue} />
            <button className="newButton" onClick={_showClinicAddPrompt}>
              <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; &nbsp;
              New
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Clinic Id</th>
                <th>Name</th>
                <th>Location</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="clinicTable">
              {filteredRows.map((clinic, clinicIndex) => (
                <tr key={clinicIndex}>
                  <td>{clinic.ClinicID}</td>
                  <td>{clinic.Name}</td>
                  <td>{clinic.Location}</td>
                  <td>
                    <button
                      className="editButton"
                      onClick={(e) => _showClinicEditPrompt(clinic)}
                    >
                      <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp;
                      Edit
                    </button>
                    <button
                      className="manageUsersBtn"
                      onClick={(e) => _manageUsers(clinic)}
                    >
                      <i className="fa fa-users" aria-hidden="true"></i> &nbsp;
                      Manage Users
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {filteredRows.length === 0 && (
            <div id="noCell">No Clinics Available</div>
          )}
        </div>
      </Header>
      <ClinicEditor
        isVisible={isClinicModalVisible}
        editMode={selectedClinic ? true : false}
        selectedClinic={selectedClinic}
        onClinicUpdate={_updateClinic}
        onCreateNewClinic={_addClinic}
        onDiscard={_hideClinicEditPrompt}
        showLoader={props.showLoader}
        hideLoader={props.hideLoader}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicManagementTable);
